
import { createTheme } from '@mui/material';
import wallpaper_background from './pics/wallpaper_background.png';
import styled, { keyframes, css } from 'styled-components';

export const textStyle_small_light = {
  fontWeight: 500,
  color: '#637381',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: '20px'
};

export const textStyle_small_dark = {
  fontWeight: 500,
  color: 'white',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};
export const textStyle_big_dark = {
  fontWeight: 500,
  color: 'white',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '1.3rem',
};
export const blue_title = {
  color: '#333333',
  fontWeight: 600
};
export const textStyle_big_account = {
  fontWeight: 500,
  color: 'white',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '1.5rem',
};
export const textStyle_big_card = {
  fontWeight: 500,
  color: '#333333',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: '1.5rem',
};
export const cardStyles_bg = {
  backgroundImage: `url(${wallpaper_background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  padding: '30px',
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '48px'
};

export const iconStyle = {
  color: '#333333',
  height: '24px',
  width: '24px'
};

export const downloadlinks = {
  fontWeight: 500,
  color: '#637381',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
};

export const inside_box = {
  color: '#637381',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: '10px',
  borderBottom: '1px solid #f0f0f0',
  width: '100%'
};

export const updatePasswordStyle = {
  ...inside_box,
  cursor: 'pointer',
  color: '#FFFFFF',
  backgroundColor: '#333333',
  padding: '8px 16px',
  borderRadius: '4px',
  display: 'inline-flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#4285F4',
  },
  width: 'calc(100% - 24px)'
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
  background: {
    default: '#FAFAFA',
    paper: '#FAFAFA',
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#637381',
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1rem',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          borderRight: '1px dotted #E0E0E0', // Change the border style to dotted
          width: 240,
          '&.reduced-drawer': {
            width: 80,
          },
          padding: '10px',
        },
        paperAnchorRight: { // This targets drawers anchored to the right
          width: '350px', // Set the new width here
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)' // Gives a hover effect to list items
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Avoids uppercase letters
          borderRadius: '8px', // Rounded edges
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)' // Adds a subtle shadow
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Rounded edges for cards
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)' // Adds a subtle shadow to cards
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          boxShadow: 'none',
          padding: '7px',
          height: '80px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
    },
    h1: {
      fontFamily: 'Poppins, sans-serif',
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
    },
    h5: {
      fontFamily: 'Poppins, sans-serif',
    },
  },
});

export const COLORS = ['#4A90E2', '#FF8042'];
