import translations from './config/translations.json';
import { getCookieValue } from './App';

export function t(key) {
  const language = getCookieValue('language') || 'it';
  // Check if the translations object has the key and language defined.
  if (translations[key] && translations[key][language]) {
    return translations[key][language];
  } else {
    // Return the key itself if translation is not found.
    return key;
  }
}

const italian_currency_formatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const italian_formatter_nosymbol = new Intl.NumberFormat('it-IT', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const italian_currency_formatter_nocents = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const number_to_italian_currency = (value) => {
  return italian_currency_formatter.format(value);
};

export const number_to_italian_nosymbol = (value) => {
  return italian_formatter_nosymbol.format(value);
};

export const number_to_italian_currency_nocents = (value) => {
  return italian_currency_formatter_nocents.format(value);
};


export const italian_currency_to_number = (amount) => {
  // Remove any non-numeric characters except comma and period
  let cleanAmount = amount.replace(/[^\d,\.]/g, '');
  
  // Replace the period used for thousands (ignore periods followed by exactly two digits)
  cleanAmount = cleanAmount.replace(/\.(?=\d{3}(\D|$))/g, '');
  
  // Replace the comma used for decimal separation
  cleanAmount = cleanAmount.replace(/,(\d{2})\b/, '.$1');
  
  // Remove any remaining commas (which now should only represent erroneous extra commas)
  cleanAmount = cleanAmount.replace(/,/g, '');
  
  return parseFloat(cleanAmount);
}


export const italian_currency_to_number_with_negative_amounts = (amount) => {
    let cleanAmount = amount.replace(/[^\d,.-]/g, '');
    cleanAmount = cleanAmount.replace(/,(\d{2})\b/, '.$1');
    if (cleanAmount.startsWith(',')) {
        cleanAmount = '0' + cleanAmount;
    }
    cleanAmount = cleanAmount.replace(/,/g, '');
    return parseFloat(cleanAmount);
};

export const formatString = (str, args) => {
  return str.replace(/%(\w+)/g, (_, key) => args[key]);
}

export const formatCardNumber = (cardNumber) => {
  let exp = /(\d{4})/g;
  let formattedNumber = cardNumber.replace(exp, '$1 ');
  formattedNumber = formattedNumber.trim();
  return formattedNumber;
}