import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, createTheme } from '@mui/material';
import translations from './config/translations.json';
import { getCookieValue, setCookieValue } from './App';
import config from './config/env.json';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { theme } from './ReactStyles';
import { get_api_url } from './Util_API_calls';

function Modal_Deletebeneficiary({ beneficiary, onClose, closeDeleteModal }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const handleDelete = async () => {
    setIsProcessing(true);

    const token = getCookieValue('firebaseToken');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
      },
      body: new URLSearchParams({
        'beneficiary_id': beneficiary.beneficiary_id
      })
    };

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/beneficiaries/delete`, requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      closeDeleteModal();
      navigate('/bank-transfers/');
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || 'An error occurred while deleting the beneficiary.');
    } finally {
      setIsProcessing(false);
    }
  };

  function formatItalianDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('it-IT', options);
    return formatter.format(date);
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className='modal_title'>
        {t('confirm_delete_beneficiary_description')}
      </DialogTitle>
      {errorMessage && (
        <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
          <div className="error-container">
            <div className="error-icon">!</div>
            <Typography className="error-text">{errorMessage}</Typography>
          </div>
        </div>
      )}

      <DialogContent>
        <div className='modal_list_with_border'>{t('payment_circuit')}: {beneficiary.payment_type}</div>
        <div className='modal_list_with_border'>{t('name_and_surname')}: {beneficiary.beneficiary_name} {beneficiary.beneficiary_surname}</div>
        <div className='modal_list_with_border'>{t('account_number')}: {beneficiary.beneficiary_iban.match(/.{1,4}/g).join(' ')}</div>
        <div className='modal_list_with_border'>{t('email_address')}: {beneficiary.beneficiary_email}</div>
        <div className='modal_list_with_border'>{t('swift_code')}: {beneficiary.beneficiary_swift_code}</div>
        <div className='modal_list_with_border'>{t('bic_code')}: {beneficiary.bank_bic}</div>
        <div className='modal_list_with_border'>{t('currency')}: {beneficiary.beneficiary_currency}</div>
        <div className='modal_list_with_border'>{t('creation_date')}: {formatItalianDate(beneficiary.created_at)}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('cancel')}
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="primary"
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_Deletebeneficiary;