import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { getCookieValue } from "./App";
import { t } from "./Util_format";
import "./css/Unauthenticated.css";

export default function Modal_Waiting_Push({
  onClose,
  errorMessage,
  isProcessing,
  onForceSendSMS,
  onAction,
}) {
  const intervalRef = useRef();
  useEffect(() => {
    if (getCookieValue("double_auth_otp_delivery_method") === "push") {
      intervalRef.current = setInterval(async () => {
        onAction();
      }, 5000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [onAction]);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className="modal_title">
        {t("waiting_to_approve_from_mobile")}
      </DialogTitle>
      <DialogContent>
        {errorMessage && (
          <div className="error-container_modal">
            <div className="error-icon">!</div>
            <Typography className="error-text">{errorMessage}</Typography>
          </div>
        )}
        <div>
          <Typography
            variant="body1"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            {t("prefer_to_send_a_sms_instead")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={`unauthenticated_button ${
              isProcessing ? "processingButton" : ""
            }`}
            disabled={isProcessing}
            onClick={onForceSendSMS}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("send_sms")
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
