import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button, Box, TextField, TableHead, Table, TableBody, TableCell, TableRow, createTheme, ThemeProvider, TableSortLabel, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import translations from './config/translations.json';
import { getCookieValue } from './App';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TablePagination from '@mui/material/TablePagination';
import Loading from './Loading';
import config from './config/env.json';
import Modal_StartCardRecharge from './Modal_StartCardRecharge';
import Modal_StartCardOrder from './Modal_StartCardOrder';
import Modal_StartCardEdit from './Modal_StartCardEdit';
import Modal_StartCardWithdraw from './Modal_StartCardWithdraw';
import { useNavigate } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/Layers';
import EditIcon from '@mui/icons-material/Edit';
import { fetchAllAccountBalances, fetchPageData } from './Util_API_calls'; // Adjust the path as necessary
import { cardStyles_bg, textStyle_big_account, textStyle_small_dark, textStyle_small_light, blue_title, iconStyle, menuItemStyle, theme } from './ReactStyles';
import { number_to_italian_currency, t } from './Util_format';
import RechargeIcon from '@mui/icons-material/AccountBalanceWallet';

function CardBalance({ accountBalances, accountId }) {
  const accountBalance = accountBalances.find((e) => e.id === accountId);
  if (accountBalance) {
    return (
      <div>
        {number_to_italian_currency(
          accountBalance.balance.available_amount ??
            accountBalance.balance.amount
        )}
      </div>
    );
  }
  return <div></div>;
}

function Page_Cards({ onDataChange, ...props }) {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [card_accounts, setcard_accounts] = useState([]);
  const [UserAccountNumberFrom, setUserAccountNumberFrom] = useState('');
  const [UserAccountIDFrom, setUserAccountIDFrom] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [isRechargeModalOpen, setIsRechargeModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCardStatus, setSelectedCardStatus] = useState(null);
  const [selectedCardID, setselectedCardID] = useState(null);
  const [accountBalances, setAccountBalances] = useState(null);
  const [isBalanceApiMaintain, setBalanceApiMaintain] = useState(false);
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();

  const renderSortLabel = (key, label) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
      className='table_header'>
      {label}
    </TableSortLabel>
  );

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const [sortedcard_accounts, setSortedcard_accounts] = useState([...card_accounts]);


  const handleRecharge = (card_id) => {
    navigate('/cards/recharge/' + card_id);
  };

  const handleEdit = (card_id,current_status) => {
    setSelectedCardStatus(current_status); 
    setselectedCardID(card_id);
    setIsEditModalOpen(true);
  };

  const handleOrder = () => {
    navigate('/cards/order');
    // setIsOrderModalOpen(true);
  }


  const handleRechargeSelectCard = () => {
    navigate('/cards/recharge/select-card');
    // setIsOrderModalOpen(true);
  }

  const handleWithdrawSelectCard = () => {
    navigate('/cards/withdraw/select-card');
  }

  const handleStartRechargeModalClose = () => {
    setIsRechargeModalOpen(false);
  };

  const handleStartWithdrawModalClose = () => {
    setIsWithdrawModalOpen(false);
  };

  const NavigateToOrderCardeModal = () => {
    alert('not yet implemented - use the app fot the time being');
  };

  const NavigateToOrderCardModal = () => {
    setIsOrderModalOpen(true);
  };

  const handleOrderModalClose = () => {
    setIsOrderModalOpen(false);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('cards');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }
      const data = apidata.data;
      setPageData(data); // Set the fetched data to pageData state
      // After setting the state, call the onDataChange with the new data to update the notification bell
      if (onDataChange) {
        onDataChange(data);
      }

      setcard_accounts(data.card_accounts);
      setcard_accounts(data.user_accounts.card_accounts);
      // console.log();
      setUserAccountNumberFrom(data.user_accounts.current_accounts[0].account_number);
      setUserAccountIDFrom(data.user_accounts.current_accounts[0].account_id);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const handleCloseRechargeModal = () => {
    setIsRechargeModalOpen(false);
  };

  const handleCloseOrderModal = () => {
    setIsOrderModalOpen(false);
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  }

  useEffect(() => {
    function sortList(card_accounts) {
      return [...card_accounts].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedcard_accounts(sortList(card_accounts));
  }, [sortConfig, card_accounts]);

  const getAccountBalances = async () => {
    try {
      const data = await fetchAllAccountBalances();
      setAccountBalances(data);
    } catch (error) {
      console.error('Error fetching all account balances:', error);
      const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
      if (isMaintain !== isBalanceApiMaintain) {
        setBalanceApiMaintain(isMaintain);
      }
    }
  };

  useEffect(() => {
    get_page_data();
    getAccountBalances();
  }, []);

  useEffect(() => {
    const filterAccounts = (accounts) => {
      return accounts.filter((account) => {
        const searchableText = [
          account.card_last_four_digits,
          account.card_type,
        ].join(' ').toLowerCase();
        return searchableText.includes(searchQuery.toLowerCase());
      });
    };

    if (searchQuery) {
      const filteredAccounts = filterAccounts(card_accounts);
      setSortedcard_accounts(filteredAccounts);
    } else {
      setSortedcard_accounts(card_accounts);
    }
  }, [searchQuery, card_accounts]);

  useEffect(() => {
    const path = location.pathname;
    const normalizedPath = path.endsWith("/") ? path.slice(0, -1) : path;
    if (
      normalizedPath === "/cards/recharge" ||
      normalizedPath === "/cards/withdraw"
    ) {
      navigate("/cards");
    } else if (normalizedPath.startsWith("/cards/recharge/select-card")) {
      setIsRechargeModalOpen(true);
    } else if (normalizedPath.startsWith("/cards/recharge/")) {
      setIsRechargeModalOpen(true);
    } else if (normalizedPath.startsWith("/cards/edit/")) {
      setIsEditModalOpen(true);
    } else if (
      normalizedPath.startsWith("/cards/withdraw/select-card") ||
      normalizedPath.startsWith("/cards/withdraw/")
    ) {
      setIsWithdrawModalOpen(true);
    }
    if (normalizedPath === "/cards/order") {
      setIsOrderModalOpen(true);
    }
  }, [location, navigate]);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <>
      {isWithdrawModalOpen && (
        <Modal_StartCardWithdraw
          isOpen={isWithdrawModalOpen}
          onClose={handleStartWithdrawModalClose}
          card_accounts={card_accounts}
          closeModal={handleStartWithdrawModalClose}
          UserAccountIDFrom={UserAccountIDFrom}
          url_card_id={location.pathname.split('/').pop()}
        />
      )}
      {isRechargeModalOpen && (
        <Modal_StartCardRecharge
          isOpen={isRechargeModalOpen}
          onClose={handleStartRechargeModalClose}
          card_accounts={card_accounts}
          closeModal={handleCloseRechargeModal}
          UserAccountNumberFrom={UserAccountNumberFrom}
          UserAccountIDFrom={UserAccountIDFrom}
          url_card_id={location.pathname.split('/').pop()}
        />
      )}
      {isOrderModalOpen && (
        <Modal_StartCardOrder
          isOpen={isOrderModalOpen}
          onClose={handleOrderModalClose}
          closeModal={handleCloseOrderModal}
          UserAccountIDFrom={UserAccountIDFrom}
          userDetails={pageData?.details}
          card_accounts={card_accounts}
        />
      )}
      {isEditModalOpen && (
        <Modal_StartCardEdit
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
          card_accounts={card_accounts}
          closeModal={handleCloseEditModal}
          UserAccountNumberFrom={UserAccountNumberFrom}
          UserAccountIDFrom={UserAccountIDFrom}
          cardId={selectedCardID}
          card_current_status={selectedCardStatus}
        />
      )}
      <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1000px' }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', }}>
              <CardContent style={{ paddingBottom: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                    {t("carte")}
                  </Typography>
                  <PaymentIcon color="primary" />
                </div>
                <div style={{ width: '100%', alignItems: 'center'}}>
                <Button
                    style={{
                      height: '40px',
                      width: isMobile ? '100%' : 'auto',
                      marginRight: '20px'
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<PaymentIcon />}
                    fullWidth={isMobile}
                    onClick={handleOrder}
                  >
                    {t("order_new_card")}
                  </Button>
                  <Button
                    style={{
                      height: '40px',
                      width: isMobile ? '100%' : 'auto',
                      marginRight: '20px'
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<RechargeIcon />}
                    fullWidth={isMobile}
                    onClick={handleRechargeSelectCard}
                  >
                    {t("recharge_your_card")}
                  </Button>
                  <Button
                    style={{
                      height: '40px',
                      width: isMobile ? '100%' : 'auto',
                      marginRight: '20px'
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<RechargeIcon />}
                    fullWidth={isMobile}
                    onClick={handleWithdrawSelectCard}
                  >
                    {t("recharge_account_from_card")}
                  </Button>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '30px' }}>
                    <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                      {t("my_cards")}
                    </Typography>
                    <RechargeIcon color="primary"/>
                  </div>
                  <TextField
                    placeholder={t("search_card")}
                    variant="outlined"
                    style={{ margin: '20px 0', maxWidth: '900px' }}
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Table style={{ maxWidth: '900px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{renderSortLabel('card_number', t('card_label'))}</TableCell>
                        {!isMobile && <TableCell>{renderSortLabel('card_type', t('card_type'))}</TableCell>}
                        {!isMobile && <TableCell>{renderSortLabel('status', t('status'))}</TableCell>}
                        {!isMobile && <TableCell>{renderSortLabel('balance', t('balance'))}</TableCell>}
                        <TableCell>{renderSortLabel('operations', t('card_recharge'))}</TableCell>
                        <TableCell>{renderSortLabel('operations', t('manage_card'))}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedcard_accounts
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((card, index) => {
                          const productKey = Object.keys(config).find(key => {
                            const configValue = config[key];
                            const productId = card.product_id;
                          
                            // Check if both configValue and productId are not null/undefined
                            if (configValue && productId) {
                              return configValue.toLowerCase() === productId.toLowerCase();
                            }
                          
                            return false;
                          });
                          const translatedProductName = productKey ? t(productKey) : card.product_id;

                          return (
                            <TableRow key={index}>
                              <TableCell className="table_body">
                                {!isMobile
                                  ? card.card_last_four_digits
                                    ? `**** **** **** ${card.card_last_four_digits}`
                                    : t("not_available")
                                  : card.card_last_four_digits
                                  ? card.card_last_four_digits
                                  : t("not_available")}
                              </TableCell>
                              <TableCell className="table_body">
                                {translatedProductName}
                              </TableCell>
                              {!isMobile && (
                                <TableCell className="table_body">
                                  {card.current_status
                                    ? t(card.current_status)
                                    : t("activating")}
                                </TableCell>
                              )}
                              {!isMobile && (
                                <TableCell className="table_body">
                                  {isBalanceApiMaintain || card.current_status === "INACTIVE" ? (
                                    <div />
                                  ) : accountBalances ? (
                                    <CardBalance
                                      accountBalances={accountBalances}
                                      accountId={card.account_id}
                                    />
                                  ) : (
                                    <CircularProgress size="1rem" />
                                  )}
                                </TableCell>
                              )}
                              <TableCell className="table_body">
                                {card.current_status === "ACTIVE" && (
                                  <IconButton
                                    onClick={() => handleRecharge(card.card_id)}
                                    color="primary"
                                  >
                                    <AccountBalanceWalletIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell className="table_body">
                                {(card.current_status === "ACTIVE" || card.current_status === "INACTIVE" || card.current_status === "BLOCKED") && (
                                  <IconButton
                                    onClick={() =>
                                      handleEdit(
                                        card.card_id,
                                        card.current_status
                                      )
                                    }
                                    color="primary"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sortedcard_accounts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('rows_per_page')}
                    labelDisplayedRows={
                      ({ from, to, count }) => {
                        return '' + from + '-' + to + ` ${t('of')} ` + count
                      }
                    }
                    className='table_bottom'
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </ThemeProvider>
      </div>
    </>
  );
}

export default Page_Cards;