import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { t } from './Util_format'; // Import translation function if needed

function Modal_ServicesListProducts({ isOpen, onClose, productType, catalog, onOpenVendorsModal }) {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to access the location object

  // Extract supplier from URL
  const supplierFromUrl = location.pathname.split('/').pop().replace(/_/g, ' ').toLowerCase();

  // Filter and sort catalog entries based on productType and supplier
  const filteredAndSortedCatalog = catalog.filter(entry =>
    entry.product_type === productType && entry.supplier.toLowerCase() === supplierFromUrl
  ).sort((a, b) => a.product_label.localeCompare(b.product_label)); // Sort by name

  // Fetch and set the remote image based on the supplier from the JSON
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    if (filteredAndSortedCatalog.length > 0) {
      const vendor = filteredAndSortedCatalog[0].supplier.replace(/\s/g, '_').toLowerCase();
      const url = '/services/' + productType + '/' + vendor;
      setImageUrl(url);
    }
  }, [filteredAndSortedCatalog]);

  // Function to handle button click for a product
  const handleButtonClick = (ean) => {
    navigate(`${location.pathname}/${ean}`); // Append the EAN to the current URL and navigate
  };  

  const handleBackButtonClick = () => {
    onClose();
    setTimeout(() => {
      const parts = location.pathname.split('/');
      const newUrl = `/services/${parts[2]}`;
      navigate(newUrl);
      onOpenVendorsModal();
    }, 0);
  };

  // Helper function to generate image path from EAN
  const getImagePathByEAN = (ean) => {
    const formattedEAN = String(ean).padStart(13, '0');
    return `/pics/epay/${formattedEAN}.webp`;
  };
  

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="category-products-dialog-title">
      <DialogTitle id="category-products-dialog-title">{t("category_" + productType)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t('services_' + productType + '_note_2')}
        </Typography><br></br>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
          {filteredAndSortedCatalog.map(product => (
            <Button
              style={{ height: "140px", width: "40%", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: '#FFFFFF', border: '1px solid #CCCCCC' }}
              variant="contained"
              onClick={() => handleButtonClick(product.ean)} // Added onClick handler here
            >
              <img
                src={getImagePathByEAN(product.ean)}
                alt={`${product.vendor} logo`}
                style={{ width: '80px', height: 'auto', paddingBottom: '10px' }}
              />
              <Typography variant="body2" style={{ color: "#2F2F2F" }}>{product.product_label}</Typography>
            </Button>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBackButtonClick} color="primary" variant="contained">
          {t('back_button')}
        </Button>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_ServicesListProducts;
