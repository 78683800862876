import { getCookieValue, setCookieValue } from "./App";
import config from "./config/env.json";
import { t } from './Util_format';
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { TextField, MenuItem } from '@mui/material';
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";

export async function get_api_url() {
  const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
  if (RETAIL_OR_BUSINESS === 'BUSINESS') {
    renderUserTenancies();
    return config.API_DOMAIN_BUSINESS;
  }

  if (RETAIL_OR_BUSINESS === 'RETAIL') {
    renderUserTenancies();
    return config.API_DOMAIN_RETAIL;
  }

  const container = document.getElementById('account-in-use');

  // Clear the container for React rendering
  if (container) {
    container.innerHTML = '';
  }

  let data = null;

  // Utility function to get a cookie
  const getAccountsFromCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
  };

  let retail_tenancies = JSON.parse(getAccountsFromCookie('retail_tenancies') || '[]');
  let business_tenancies = JSON.parse(getAccountsFromCookie('business_tenancies') || '[]');

  try {
    const token = getCookieValue("firebaseToken");
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/user/profile`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error({ status: 'error', error_code: response.status, fdt_error_code: errorResponse.fdt_error_code });
      return;
    }

    data = await response.json();

    if (data && data.retail && data.retail.access_name) {
      retail_tenancies = [
        {
          type: 'retail',
          value: data.retail.customer_id,
          label: data.retail.access_name,
          inUse: true
        },
      ];
      setCookieValue('retail_tenancies', JSON.stringify(retail_tenancies), 7);
    }

    if (data && data.business && data.business.length > 0) {
      business_tenancies = data.business.map(business => ({
        type: 'business',
        business_id: business.business_id,
        value: business.business_id,
        label: business.access_name,
        inUse: false,
      }));
      business_tenancies[0].inUse = true; // Set the first business account as in use
      setCookieValue('business_tenancies', JSON.stringify(business_tenancies), 7);
    }

    renderUserTenancies();
    if (data && data.retail && Object.keys(data.retail).length > 0) {
      setCookieValue('RETAIL_OR_BUSINESS', 'RETAIL');
      return config.API_DOMAIN_RETAIL;
    }
    if (data && data.business && data.business.length > 0) {
      setCookieValue('RETAIL_OR_BUSINESS', 'BUSINESS');
      return config.API_DOMAIN_BUSINESS;
    }
    // DEFAULT TO RETAIL
    setCookieValue('RETAIL_OR_BUSINESS', 'RETAIL');
    return config.API_DOMAIN_RETAIL;
  } catch (error) {
    console.error('Error fetching data:', error);
    return;
  }
}

function renderUserTenancies() {
  // Utility function to get a cookie
  const getAccountsFromCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
  };

  let personalAccounts = JSON.parse(getAccountsFromCookie('retail_tenancies') || '[]');
  let businessAccounts = JSON.parse(getAccountsFromCookie('business_tenancies') || '[]');

  const container = document.getElementById('account-in-use');
  if (!container) return;

  const accounts = [...personalAccounts, ...businessAccounts];

  // Create root only if it doesn't exist
  if (!container._reactRootContainer) {
    const root = createRoot(container);
    container._reactRootContainer = root;
  }

  container._reactRootContainer.render(
    <TextField
      select
      name="accountInUse"
      fullWidth
      margin="dense"
      style={{ minWidth: '250px', marginRight: '10px', color: "#637381", fontFamily: 'Poppins' }}
      value={accounts.find(account => account.inUse)?.value || ''}
      onChange={(event) => handleTenancyChange(event, accounts, personalAccounts, businessAccounts)}
    >
      {accounts.map(account => (
        <MenuItem key={account.value} value={account.value}>
          {account.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function handleTenancyChange(event, accounts, personalAccounts, businessAccounts) {
  const selectedTenancy = accounts.find(account => account.value === event.target.value);
  accounts.forEach(account => account.inUse = false);
  if (selectedTenancy) selectedTenancy.inUse = true;
  setCookieValue('retail_tenancies', JSON.stringify(personalAccounts), 7);
  setCookieValue('business_tenancies', JSON.stringify(businessAccounts), 7);
  setCookieValue('RETAIL_OR_BUSINESS', selectedTenancy.value === personalAccounts[0].value ? 'RETAIL' : 'BUSINESS');
  if (selectedTenancy.type === 'business') {
    setCookieValue('business_id', selectedTenancy.business_id);
  } else {
    setCookieValue('business_id', '');
  }
  renderUserTenancies(); // Re-render to update inUse status
  window.location.reload();
}

// Ensure rendering on page reload
document.addEventListener("DOMContentLoaded", () => {
  const personalAccounts = JSON.parse(getCookieValue('retail_tenancies') || '[]');
  const businessAccounts = JSON.parse(getCookieValue('business_tenancies') || '[]');
  renderUserTenancies(personalAccounts, businessAccounts);
});


export async function fetchAllAccountBalances() {
  try {
    const token = getCookieValue("firebaseToken");
    const API_URL = await get_api_url();

    const business_id = getCookieValue("business_id") || "";
    const formData = new FormData();
    if (business_id) {
      formData.append("business_id", business_id);
    }

    const response = await fetch(`${API_URL}/api/baas/balances/all-accounts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
    );

    if (!response.ok) {
      const rawResponse = await response.text();
      const errorResponse = JSON.parse(rawResponse);
      if (errorResponse.fdt_error_code === "UNLIMIT_PLANNED_MAINTENANCE") {
        throw new Error("UNLIMIT_PLANNED_MAINTENANCE");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    const result = await response.json();
    return result.accounts ? result.accounts : result.data;
  } catch (error) {
    console.error("Error fetching bank account balance:", error);
  }
}

export async function fetchBankAccountBalance(accountId) {
  const token = getCookieValue("firebaseToken");
  try {
    const formData = new FormData();
    formData.append("accountId", accountId);
    const business_id = getCookieValue("business_id") || "";
    if (business_id) {
      formData.append("business_id", business_id);
    }
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/baas/balances/single-account`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
    );

    if (!response.ok) {
      const rawResponse = await response.text();
      const errorResponse = JSON.parse(rawResponse);
      if (errorResponse.fdt_error_code === "UNLIMIT_PLANNED_MAINTENANCE") {
        throw new Error("UNLIMIT_PLANNED_MAINTENANCE");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    const result = await response.json();
    const formatter2 = new Intl.NumberFormat("it-IT", {
      style: "currency", // set style to currency
      currency: result.accounts[0].balance.currency, // use the currency key from your transaction data
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return (
      result.accounts[0].balance.available_amount ??
      result.accounts[0].balance.amount
    ); // Return the formatted balance
  } catch (error) {
    console.error("Error fetching bank account balance:", error);
  }
}

export async function fetchBankTransferFee(
  payment_type,
  from,
  to,
  amount,
  currency,
  priority,
  beneficiary_bank_code,
  beneficiary_country
) {
  const token = getCookieValue("firebaseToken");
  try {
    const formData = new FormData();
    formData.append("payment_type", payment_type);
    formData.append("from", from);
    formData.append("to", to);
    formData.append("amount", amount);
    formData.append("currency", currency);
    formData.append("priority", priority);
    formData.append(
      "beneficiary_bank_code",
      beneficiary_bank_code.length === 8
        ? `${beneficiary_bank_code}XXX`
        : beneficiary_bank_code
    );
    formData.append("beneficiary_country", beneficiary_country);
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/baas/transfer/calculate_fee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
    );
    const result = await response.json();
    return result.calculate_payments[0].fee_amount; // Return the formatted balance
  } catch (error) {
    console.error("Error fetching SWIFT fee:", error);
  }
}

export async function fetchPageData(page_to_fetch) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
    const formattedPageToFetch = page_to_fetch.replace(/_/g, '-');
    const response = await fetch(
      `${API_URL}/api/user/pages/desktop/${formattedPageToFetch}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body:
          RETAIL_OR_BUSINESS === "BUSINESS"
            ? JSON.stringify({ business_id: getCookieValue("business_id") })
            : null,
      }
    );

    // Check if the response is not okay and handle the error
    if (!response.ok) {
      const errorResponse = await response.json();  // Extract JSON from the response
      return { status: 'error', error_code: response.status, fdt_error_code: errorResponse.fdt_error_code };
    }

    const result = await response.json();
    // console.log("result", result);

    if (
      result.current_month_transactions &&
      result.current_month_transactions.cards
    ) {
      for (const [key, value] of Object.entries(
        result.current_month_transactions.cards
      )) {
        const filteredTransations = value.filter(
          (e) => e.rrn && (e.transaction_created_at || e.created_at)
        );
        const sortedTransactions = filteredTransations.sort(
          (a, b) =>
            new Date(b.transaction_created_at ?? b.created_at) -
            new Date(a.transaction_created_at ?? a.created_at)
        );
        const rrnTransactions = Object.groupBy(
          sortedTransactions,
          ({ rrn }) => rrn
        );
        function removeElement(array, elem) {
          var index = array.indexOf(elem);
          if (index > -1) {
            array.splice(index, 1);
          }
        }
        for (const [_, rrnValue] of Object.entries(rrnTransactions)) {
          if (rrnValue.length > 1) {
            rrnValue.slice(1).forEach((e) => {
              removeElement(value, e);
            })
          }
        }
      }
    }

    return { status: 'success', data: result };
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
}

export async function markMessageAsRead(msg_id) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(
      `${API_URL}/api/user/messages/mark-as-read`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ msg_id: msg_id }),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export async function fetchCardIframeURL(card_id, type) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(
      `${API_URL}/api/baas/links/iframe`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ type: type, locale: "EN", card_id: card_id }),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export async function fetchCardSenstiveData(card_id, type) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/baas/cards/card_sensitive_data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ card_id: card_id }),
    }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export async function fetchCardTransactions(
  card_id,
  transaction_type,
  from,
  to,
  timezone
) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/baas/transactions/card`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        card_id: card_id,
        from: from,
        to: to,
        transaction_type: transaction_type,
        timezone: timezone,
      }),
    }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();

    if (result.transactions) {
      const filteredTransations = result.transactions.filter(
        (e) => e.rrn && (e.transaction_created_at || e.created_at)
      );
      const sortedTransactions = filteredTransations.sort(
        (a, b) =>
          new Date(b.transaction_created_at ?? b.created_at) -
          new Date(a.transaction_created_at ?? a.created_at)
      );
      console.log(sortedTransactions);
      const rrnTransactions = Object.groupBy(
        sortedTransactions,
        ({ rrn }) => rrn
      );
      function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
          array.splice(index, 1);
        }
      }
      for (const [_, rrnValue] of Object.entries(rrnTransactions)) {
        if (rrnValue.length > 1) {
          rrnValue.slice(1).forEach((e) => {
            removeElement(result.transactions, e);
          })
        }
      }
    }

    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export async function fetchBankAccountTransactions(
  account_id,
  transaction_type,
  from,
  to,
  timezone
) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/baas/transactions/bankaccount`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        account_id: account_id,
        from: from,
        to: to,
        transaction_type: transaction_type,
        timezone: timezone,
      }),
    }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export async function fetchSendLoginOTP() {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/auth/login-mfa/send-verification`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        device_type: "desktop",
        delivery_method: 'preferred'
      }),
    }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export async function fetchVerifyLoginOTP(otpCode) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/auth/login-mfa/verify-otp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        otp_code: otpCode,
      }),
    });

    const result = await response.json();

    if (!response.ok) {
      return {
        status: "error",
        message: result.message,
        details: result.details,
      };
    }

    // Return success
    return { status: "success", data: result };
  } catch (error) {
    console.error("Error calling the API:", error);
    return {
      status: "error",
      errorDetails: { general: ["Network error occurred."] },
    };
  }
}

export async function fetchEpayMobileRechargePay(accountId, phoneNumber, ean, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/phone_recharge/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        amount: parseFloat(amount).toFixed(2),
        ean: ean,
        phone_number: phoneNumber,
        accountId: accountId,
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          // Add more cases as needed
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}

export async function fetchEpayVoucherPin(accountId, ean, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/voucher_with_pin/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        amount: parseFloat(amount).toFixed(2),
        ean: ean,
        accountId: accountId,
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          case "INVALID_EAN_AMOUNT":
            errorMsg = t('invalid_ean_amount');
            break;
          // Add more cases as needed
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}

export async function fetchEpayMooney_rav(accountId, category_name, rav_id, first_name, last_name, phone_number, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/rav/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        accountId: accountId,
        rav_id: rav_id,
        amount: parseFloat(amount).toFixed(2),
        category_name: category_name,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          case "INVALID_EAN_AMOUNT":
            errorMsg = t('invalid_ean_amount');
            break;
          case "EPAY_PRICE_UNKNOWN":
            errorMsg = t('epay_price_unknown');
            break;
          case "INPUTS_ERROR":
            errorMsg = t('epay_inputs_error');
            break;
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}

export async function fetchEpayMooney_mav(accountId, category_name, mav_id, first_name, last_name, phone_number, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/mav/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        accountId: accountId,
        mav_id: mav_id,
        amount: parseFloat(amount).toFixed(2),
        category_name: category_name,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          case "INVALID_EAN_AMOUNT":
            errorMsg = t('invalid_ean_amount');
            break;
          case "EPAY_PRICE_UNKNOWN":
            errorMsg = t('epay_price_unknown');
            break;
          case "INPUTS_ERROR":
            errorMsg = t('epay_inputs_error');
            break;
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}

export async function fetchEpayMooney_bolloauto(accountId, category_name, vehicle_type, vehicle_plate_number, first_name, last_name, phone_number, email, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/bolloauto/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        accountId: accountId,
        vehicle_type: vehicle_type,
        vehicle_plate_number: vehicle_plate_number,
        amount: parseFloat(amount).toFixed(2),
        category_name: category_name,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        email: email,
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          case "INVALID_EAN_AMOUNT":
            errorMsg = t('invalid_ean_amount');
            break;
          case "EPAY_PRICE_UNKNOWN":
            // errorMsg = t('epay_price_unknown');
            errorMsg = t('epay_inputs_error');
            break;
          case "INPUTS_ERROR":
            errorMsg = t('epay_inputs_error');
            break;
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}


export async function fetchEpayMooney_pagopa(accountId, category_name, ID_AGID, ID_DOMINIO, first_name, last_name, phone_number, email, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/pagopa/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        accountId: accountId,
        id_agid: ID_AGID,
        id_dominio: ID_DOMINIO,
        amount: parseFloat(amount).toFixed(2),
        category_name: category_name,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        email: email,
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          case "INVALID_EAN_AMOUNT":
            errorMsg = t('invalid_ean_amount');
            break;
          case "EPAY_PRICE_UNKNOWN":
            // errorMsg = t('epay_price_unknown');
            errorMsg = t('epay_inputs_error');
            break;
          case "INPUTS_ERROR":
            errorMsg = t('epay_inputs_error');
            break;
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}

export async function fetchEpayMooney_bollettini896(accountId, category_name, ccp, code, first_name, last_name, phone_number, email, amount) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const response = await fetch(`${API_URL}/api/epay/bollettini896/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        currency: "EUR",
        accountId: accountId,
        ccp: ccp,
        code: code,
        amount: parseFloat(amount).toFixed(2),
        category_name: category_name,
        first_name: first_name,
        last_name: last_name,
        phone_number: phone_number,
        email: email,
        type: 'standard'
      }),
    }
    );

    if (!response.ok) {
      // Assume the response is in JSON format
      const errorData = await response.json();

      let errorMsg;
      if (errorData.fdt_error_code) {
        switch (errorData.fdt_error_code) {
          case "EPAY_RETURNED_ERROR":
            errorMsg = t('technical_error_description');
            break;
          case "INVALID_EAN_AMOUNT":
            errorMsg = t('invalid_ean_amount');
            break;
          case "EPAY_PRICE_UNKNOWN":
            // errorMsg = t('epay_price_unknown');
            errorMsg = t('epay_inputs_error');
            break;
          case "INPUTS_ERROR":
            errorMsg = t('epay_inputs_error');
            break;
          default:
            errorMsg = "Unexpected error occurred";
            break;
        }
      } else {
        errorMsg = "Unexpected error occurred";
      }

      // It's a good practice to create an Error object to preserve stack trace
      const error = new Error(errorMsg);
      error.code = response.status; // Attach the HTTP status code to the error object
      error.details = errorData.details; // Attach additional details if needed
      throw error;
    }

    const result = await response.json();
    return result.transaction_id;
  } catch (error) {
    // If an error occurs while fetching or parsing the response, throw it to be handled by the caller
    throw error;
  }
}

export async function fetchConfirmEpayOtp(transactionId, otpCode) {
  const token = getCookieValue("firebaseToken");
  const API_URL = await get_api_url();
  const response = await fetch(`${API_URL}/api/epay/confirm_baas_otp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      otp_code: otpCode,
      transaction_id: transactionId,
    }),
  }
  );
  if (!response.ok) {
    // Assume the response is in JSON format
    const errorData = await response.json();

    let errorMsg;
    if (errorData.fdt_error_code) {
      switch (errorData.fdt_error_code) {
        case "INVALID_OTP":
          errorMsg = t('wrong_top_code');
          break;
        case "TRANSACTION_IS_NOT_DRAFT":
          errorMsg = t('transaction_not_draft');
          break;
        case "UNLIMIT_OTP_EXPIRED":
          errorMsg = t('otp_code_expired_please_request_a_new_one');
          break;
        case "UNLIMIT_OTP_ATTEMPTS_LIMIT_EXCEEDED":
          errorMsg = t('otp_attempts_limit_exceeded');
          break;
        default:
          errorMsg = "Unexpected error occurred";
          console.log("errorData", errorData);
          break;
      }
    } else {
      errorMsg = "Unexpected error occurred";
    }

    // It's a good practice to create an Error object to preserve stack trace
    const error = new Error(errorMsg);
    error.code = response.status; // Attach the HTTP status code to the error object
    error.details = errorData.details; // Attach additional details if needed
    throw error;
  }
  const result = await response.json();
  return result;
}

export async function fetchResendEpayOtp(transactionId) {
  const token = getCookieValue("firebaseToken");
  const API_URL = await get_api_url();
  const response = await fetch(`${API_URL}/api/epay/resend_baas_otp`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      transaction_id: transactionId,
    }),
  }
  );
  if (!response.ok) {
    // Assume the response is in JSON format
    const errorData = await response.json();

    let errorMsg;
    if (errorData.fdt_error_code) {
      switch (errorData.fdt_error_code) {
        default:
          errorMsg = "Unexpected error occurred";
          console.log("errorData", errorData);
          break;
      }
    } else {
      errorMsg = "Unexpected error occurred";
    }

    // It's a good practice to create an Error object to preserve stack trace
    const error = new Error(errorMsg);
    error.code = response.status; // Attach the HTTP status code to the error object
    error.details = errorData.details; // Attach additional details if needed
    throw error;
  }
  const result = await response.json();
  return result;
}


export async function changeUserLanguage(language) {
  const token = getCookieValue("firebaseToken");
  if (!token) return;
  try {
    const API_URL = await get_api_url();
    await fetch(
      `${API_URL}/api/user/settings/update/general/user_language`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ value: language }),
      }
    );
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

  export async function sendUserEmailVerification(uid) {
    const response = await fetch(
      `${config.ACCOUNT_BASE_URL}/firebase_send_registration_email?userId=${uid}&language=${getCookieValue("language") === "en" ? "en" : "it"}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const result = await response.json();
    if (!result.success || result.success !== true)
      throw new Error("Unexpected error occurred");
    return true;
  }
