import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchBankAccountTransactions, fetchCardTransactions } from './Util_API_calls';
import { generatePDF } from './Util_generatePDF';

const LinkGeneratePDF = () => {
  const { bankAccountId, cardId, year, month } = useParams();

  useEffect(() => {
    const fetchAndGeneratePDF = async () => {
      try {
        const fromDate = new Date(year, month - 1, 1);
        const toDate = new Date(year, month, 0);

        const formattedFromDate = `${fromDate.getFullYear()}${String(fromDate.getMonth() + 1).padStart(2, '0')}${String(fromDate.getDate()).padStart(2, '0')}`;
        const formattedToDate = `${toDate.getFullYear()}${String(toDate.getMonth() + 1).padStart(2, '0')}${String(toDate.getDate()).padStart(2, '0')}`;

        let data;
        let accountType;
        if (bankAccountId) {
          data = await fetchBankAccountTransactions(bankAccountId, 'ALL', formattedFromDate, formattedToDate, 'CET');
          accountType = "bankaccount";
        } else if (cardId) {
          data = await fetchCardTransactions(cardId, 'ALL', formattedFromDate, formattedToDate, 'CET');
          accountType = "card";
        }

        if (data && data.transactions.length > 0) {
          const monthYear = `${year}-${month.toString().padStart(2, '0')}`;
          generatePDF(accountType, data.transactions, {}, [], monthYear);
        } else {
          console.error('No transactions available for export.');
        }
      } catch (error) {
        console.error('Error in generating PDF:', error);
      }
    };

    if ((bankAccountId || cardId) && month && year) {
      fetchAndGeneratePDF();
    }
  }, [bankAccountId, cardId, month, year]);

  return <div>Exporting PDF...</div>;
};

export { LinkGeneratePDF };
