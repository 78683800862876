import React, { useState, useEffect } from 'react';
import config from './config/env.json';
import logopng from './pics/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, InputAdornment, Modal, Typography, Button, Link, CircularProgress } from '@mui/material';
import { get_api_url } from './Util_API_calls';

function KillSessions() {
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const email = searchParams.get('email');
  const killString = searchParams.get('kill_string');

  const handleLogin = async () => {
    navigate("/login");
  }
  useEffect(() => {
    if (!email || !killString) {
      setError('Missing required parameters.');
      return;
    }
    handleKillSessions();
  }, [email, killString]);

  const handleKillSessions = async () => {
    setIsProcessing(true);
    setSuccessMessage(null);
    setError(null);

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/auth/session/kill-all`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          kill_string: killString,
          email: email
        })
      });

      if (!response.ok) {
        if (response.status === 500) {
          throw new Error("unknown_api_error");
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setSuccessMessage("Sessions terminated successfully!");

    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const messageBoxStylesSuccess = {
    padding: '40px',
    border: '2px solid #4CAF50',  // Green for success
    backgroundColor: '#e8f5e9',  // Light green background for success
    color: '#4CAF50',            // Green text for success
    maxWidth: '80%',
    textAlign: 'center',
    borderRadius: '8px'
  };

  const messageBoxStylesError = {
    padding: '40px',
    border: '2px solid #d32f2f',  // Red for error
    backgroundColor: '#ffeeee',  // Light red background for error
    color: '#d32f2f',            // Red text for error
    maxWidth: '80%',
    textAlign: 'center',
    borderRadius: '8px'
  };

  return (
    <Box id="page_kill_session" display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" bgcolor="#f3f4f6">
      <img src={logopng} alt="Logo" style={{ width: '45px', height: '75px', marginBottom: '40px' }} />
      {isProcessing ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress size={48} />
          <Typography variant="h6" style={{ marginTop: '16px' }}>Disconnessione in atto...</Typography>
        </Box>
      ) : (
        <>
          {error && (
            <Box style={messageBoxStylesError}>
              <Typography variant="h6">Si è verificato un errore durante la disconnessione. Si prega di contattare il servizio clienti.</Typography>
            </Box>
          )}
          {successMessage && (
            <Box style={messageBoxStylesSuccess}>
              <Typography variant="h6">Operazione riuscita: disconnessione avvenuta con successo.</Typography>
            </Box>
          )}
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        className="unauthenticated_button"
        sx={{
          borderRadius: '1rem',
          textTransform: 'none',
          backgroundColor: isProcessing ? '#AAAAAA !important' : undefined,
          color: isProcessing ? '#FFFFFF !important' : undefined,
          marginTop: '40px',
          width: '240px !important'
        }}
        onClick={handleLogin}
        disabled={isProcessing}
      >
        Torna al login
      </Button>
    </Box>

  );
}

export default KillSessions;
