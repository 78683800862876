import React, { useState, useEffect, useRef } from 'react';

import { Box, Grid, Card, CardContent, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, createTheme, ThemeProvider, CircularProgress, Modal } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import translations from './config/translations.json';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import DevicesIcon from '@mui/icons-material/Devices';
import LockIcon from '@mui/icons-material/Lock';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { getCookieValue, setCookieValue } from './App';
import Modal_UpdateEmail from './Modal_UpdateEmail';
import Modal_UpdatePhone from './Modal_UpdatePhone';
import Modal_UpdateResidentialAddress from './Modal_UpdateResidentialAddress';
import Modal_UpdatePassword from './Modal_UpdatePassword';
import config from './config/env.json';
import Loading from './Loading';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchPageData } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { useNavigate } from 'react-router-dom';
import {  textStyle_small_light, blue_title, downloadlinks, inside_box, updatePasswordStyle } from './ReactStyles';
import { theme } from './ReactStyles';
import { get_api_url } from './Util_API_calls';

const initialDetails = {}

function Page_Profile({ onDataChange, ...props }) {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [details, setDetails] = useState(initialDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputsRef = useRef([]);
  const [otp] = useState(Array(6).fill(""));
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSessions, setActiveSessions] = useState([]);
  const [currentUserAddress, setCurrentUserAddress] = useState(null);
  const [editField, setEditField] = useState(null);
  const navigate = useNavigate();

  // for modal
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [htmlContent, setHtmlContent] = useState("");
  const currentLanguage = getCookieValue("language");


  const handleEditClick = (field) => {
    setEditField(field);
    setIsModalOpen(true);
  };

  const handleSave = (field, newValue) => {
    setDetails({ ...details, [field]: newValue });
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setEditField(null);
    setIsModalOpen(false);
  };


  const fetchHtmlForModal = (routeName) => {
    const terms_url =
      currentLanguage === "en"
        ? `./docs/${routeName}-en.html`
        : `./docs/${routeName}-it.html`;

    fetch(terms_url) // Adjust the path if necessary
      .then((response) => response.text())
      .then((content) => {
        setHtmlContent(content);
        setOpen(true);
      });
  };


  const downloadPdf = (fileName) => {
    const terms_url =
      currentLanguage === "en"
        ? `./docs/${fileName}-en.pdf`
        : `./docs/${fileName}-it.pdf`;

    const downloadLink = document.createElement('a');
    downloadLink.href = terms_url; // Replace with your actual file path
    downloadLink.download = `${terms_url}.pdf`;
    downloadLink.click();
  };

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('profile');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }
      const data = apidata.data;
      // After setting the state, call the onDataChange with the new data to update the notification bell
      if (onDataChange) {
        onDataChange(data);
      }

      setActiveSessions(data.sessions.mobile);
      setUserData(data); // Set the fetched data to userData
      // Update the details with the fetched user data
      setDetails({
        name_and_surname: `${data.details.name} ${data.details.last_name}`,
        //    date_of_birth: data.details.dob || '',
        email: data.details.email || '',
        phone: data.details.phone || '',
        address: `${data.details.addr_line_1}${data.details.addr_line_2 ? `, ${data.details.addr_line_2}` : ''}${data.details.zip_code ? `, ${data.details.zip_code}` : ''}${data.details.city ? `, ${data.details.city}` : ''}${data.details.state ? `, ${data.details.state}` : ''}${data.details.country ? `, ${data.details.country}` : ''}`,
        kyc_status: t('completed') || '',
      });
      setCurrentUserAddress(data.details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  // Updated killSession function
  const killSession = async (sessionId) => {
    const token = getCookieValue('firebaseToken');

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/auth/session/kill`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${token}`
        },
        body: `session_id=${encodeURIComponent(sessionId)}`
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // If the API call was successful, initiate the animation
      const sessionElement = document.querySelector(`[data-session-id="${sessionId}"]`);
      if (sessionElement) {
        sessionElement.classList.add('session-fade-out');
        // Wait for the animation to finish before removing the session from state
        setTimeout(() => {
          setActiveSessions(currentSessions => {
            // If currentSessions is not an array (falsy), return an empty array
            if (!Array.isArray(currentSessions)) {
              return [];
            }
            // Filter out the session that was just killed
            return currentSessions.filter(session => session.session_id !== sessionId);
          });
        }, 300); // Corresponds to the CSS transition duration
      }
    } catch (error) {
      console.error('Error killing session:', error);
    }
  };


  useEffect(() => {
    get_page_data();

  }, []);

  if (isLoading) {
    return <Loading></Loading>;
  }


  function EditDialog({ field, details, isOpen, onClose, onSave }) {
    const [value, setValue] = useState(details[field] || '');


    useEffect(() => {

      setValue(details[field] || '');
    }, [field, details]);

    useEffect(() => {
      inputsRef.current = inputsRef.current.slice(0, otp.length);
    }, []);

    if (!isOpen) {
      return null;
    }

    switch (field) {
      case 'phone':
        return <Modal_UpdatePhone value={value} onSave={onSave} onClose={onClose} />;
      case 'email':
        return <Modal_UpdateEmail value={value} onSave={onSave} onClose={onClose} />;
      case 'address':
        if (details && details.address) {
          return <Modal_UpdateResidentialAddress onSave={onSave} onClose={onClose} address={currentUserAddress} />;
        }
      case 'password':
        return <Modal_UpdatePassword value={value} onSave={onSave} onClose={onClose} />;
    }
  }

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        {isModalOpen && (
          <EditDialog
            field={`${editField}`}
            details={details}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSave}
          />
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="custom-modal"
          sx={{ width: "100%", minWidth: 400 }} // Set minimum width here
        >
          <div className="modal-content">
            <Typography id="modal-description" className="modal-description">
              <div
                className="carta_reale_terms_and_conditions"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
              <Button
                sx={{ marginTop: "30px" }}
                variant="contained"
                className="unauthenticated_button"
                onClick={handleClose}
              >
                {t("close")}
              </Button>
            </Typography>
          </div>
        </Modal>

        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12}>
          </Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: 'auto' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                    {t("personal_details")}
                  </Typography>
                  <PersonIcon color="primary" />
                </div>
                <div>
                  {Object.entries(details).map(([key, value]) => (
                    <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '0.5rem' }}>
                      <Typography style={{ ...inside_box }}>
                        <strong>{`${t(key)}`}</strong>: {value}
                      </Typography>
                      {key === 'email' && (
                        <IconButton onClick={() => handleEditClick('email')} size="small">
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      )}
                      {key === 'phone' && (
                        <IconButton onClick={() => handleEditClick('phone')} size="small">
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      )}
                      {key === 'address' && (
                        <IconButton onClick={() => handleEditClick('address')} size="small">
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ minWidth: isMobile ? "300px" : "500px" }}
          >
            <Card
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                height: "auto",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      ...textStyle_small_light, ...blue_title,
                      textTransform: "capitalize",
                    }}
                  >
                    {t("legal")}
                  </Typography>
                </div>
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: { xs: 4, sm: 8, lg: 12 },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          ...downloadlinks,
                        }}>{t("terms_and_conditions")}</Typography>

                      <a href="#" onClick={() => downloadPdf('termini')}>
                        <FileDownloadIcon color="primary" />
                      </a>

                      <button
                        style={{
                          ...updatePasswordStyle,
                          textTransform: "capitalize",
                          justifyContent: "center",
                          textDecoration: "none",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          width: "120px"
                        }}
                        type="button"
                        onClick={() => fetchHtmlForModal("termini")}
                      >
                        {t("view")}
                      </button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          ...downloadlinks,
                        }}>{t("privacy_and_cookies")}</Typography>
                      <a href="#" onClick={() => downloadPdf('privacy_policy')}>
                        <FileDownloadIcon color="primary" />
                      </a>
                      <button
                        style={{
                          ...updatePasswordStyle,
                          textTransform: "capitalize",
                          justifyContent: "center",
                          textDecoration: "none",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          width: "120px"
                        }}
                        type="button"
                        onClick={() => fetchHtmlForModal("privacy_policy")}
                      >
                        {t("view")}
                      </button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          ...downloadlinks,
                        }}>{t("google_pay_tnc")}</Typography>
                      <a href="#" onClick={() => downloadPdf('google_pay')}>
                        <FileDownloadIcon color="primary" />
                      </a>
                      <button
                        style={{
                          ...updatePasswordStyle,
                          textTransform: "capitalize",
                          justifyContent: "center",
                          textDecoration: "none",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          width: "120px"
                        }}
                        type="button"
                        onClick={() => fetchHtmlForModal("google_pay")}
                      >
                        {t("view")}
                      </button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        style={{
                          ...downloadlinks,
                        }}>{t("apple_pay_tnc")}</Typography>
                      <a href="#" onClick={() => downloadPdf('apple_pay')}>
                        <FileDownloadIcon color="primary" />
                      </a>
                      <button
                        style={{
                          ...updatePasswordStyle,
                          textTransform: "capitalize",
                          justifyContent: "center",
                          textDecoration: "none",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          width: "120px"
                        }}
                        type="button"
                        onClick={() => fetchHtmlForModal("apple_pay")}
                      >
                        {t("view")}
                      </button>
                    </Box>
                  </Box>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: 'auto' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                    {t("active_sessions_mobile")}
                  </Typography>
                  <DevicesIcon color="primary" />
                </div>
                <div style={{ paddingTop: '20px' }}>
                  {activeSessions.length > 0 ? activeSessions.map(session => (
                    <div
                      key={session.session_id}
                      data-session-id={session.session_id} // Ensure this data attribute is set correctly
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '10px',
                        transition: 'opacity 0.3s ease, max-height 0.3s ease', // You can also define this in your CSS
                        opacity: 1, // Start with an opaque element
                        maxHeight: '200px', // Adjust as needed, must be greater than the actual height
                      }}
                    >
                      <Typography style={{ ...inside_box }}>
                        {session.device_name || 'Unknown Device'} - {session.device_model || 'Unknown Model'}
                      </Typography>
                      <IconButton onClick={() => killSession(session.session_id)} size="small">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  )) : <Typography style={{ ...inside_box }}>
                    {t("no_active_sessions")}
                  </Typography>}
                </div>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: 'auto' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }} >
                    {t("security_settings")}
                  </Typography>
                  <LockIcon color="primary" />
                </div>
                <div style={{ paddingTop: '20px' }}>
                  {/* Replace Button with a text link or any other element as needed */}
                  <Typography style={updatePasswordStyle} onClick={() => handleEditClick('password')}>
                    <VpnKeyIcon style={{ marginRight: '8px' }} /> {t('update_password_button')}
                  </Typography>

                </div>
              </CardContent>
            </Card>
          </Grid>


        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Profile;