import React, { useEffect, useState } from 'react';
import { getCookieValue } from './App';
import './css/Unauthenticated.css';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, Grid, Box, Typography, CircularProgress } from '@mui/material';
import { t } from './Util_format';
import config from './config/env.json';
import { get_api_url } from './Util_API_calls';

const countryOptions = [
  { id: 'it', name: 'Italia' },
];

const validatePartitaIVA = (partitaIVA) => {
  const partitaIVARegex = /^\d{11}$/;
  return partitaIVARegex.test(partitaIVA);
};

const validatePhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/;
  return phoneNumberRegex.test(phoneNumber);
};

function Kyb() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    country_id: 'it', // Set Italy as the default selected option
    business_number: '',
    mobile_phone_number: '',
  });
  const [apiData, setApiData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [kybCompleted, setKybCompleted] = useState(false);

  useEffect(() => {
    const token = getCookieValue("firebaseToken");
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFetchCompanyDetails = async (e) => {
    e.preventDefault();
    if (!validatePartitaIVA(formData.business_number)) {
      setErrorMessage("Please enter a valid Partita IVA (11 numeric characters).");
      return;
    }
    if (!validatePhoneNumber(formData.mobile_phone_number)) {
      setErrorMessage("Please enter a valid mobile phone number.");
      return;
    }
    setLoading(true);
    try {
      const result = await fetchCompanyDetails();
      setApiData(result);
      console.log("API response:", result);
      setErrorMessage(""); // Clear any previous error messages
      // Handle the API response here
    } catch (error) {
      console.error("Error submitting the form:", error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchCompanyDetails() {
    try {
      const token = getCookieValue("firebaseToken");
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/kyb/get-business-details`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: formData.country_id,
          business_number: formData.business_number,
          mobile_phone_number: formData.mobile_phone_number,
        }),
      }
      );

      if (!response.ok) {
        const rawResponse = await response.text();
        const errorResponse = JSON.parse(rawResponse);
        handleErrorResponse(errorResponse);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.data;
    } catch (error) {
      console.error("Error fetching company details:", error);
      setErrorMessage("Error fetching company details. Please try again.");
    }
  }

  const handlefetchSubmitKybApplication = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await fetchSubmitKybApplication();
      setApiData(result);
      console.log("API response:", result);
      setErrorMessage(""); // Clear any previous error messages
      setKybCompleted(true);
    } catch (error) {
      console.error("Error submitting the form:", error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchSubmitKybApplication() {
    try {
      const token = getCookieValue("firebaseToken");
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/kyb/submit-application`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: formData.country_id,
          business_number: formData.business_number,
          mobile_phone_number: formData.mobile_phone_number,
        }),
      }
      );

      if (!response.ok) {
        const rawResponse = await response.text();
        const errorResponse = JSON.parse(rawResponse);
        handleErrorResponse(errorResponse);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.data;
    } catch (error) {
      console.error("Error submitting application:", error);
      setErrorMessage("Error submitting application. Please try again.");
    }
  }

  const handleErrorResponse = (errorResponse) => {
    if (errorResponse.fdt_error_code === "INVALID_BUSINESS_NUMBER") {
      setErrorMessage("Invalid Business Number. Please check the number and try again.");
    } else {
      setErrorMessage(errorResponse.message || "An error occurred. Please try again.");
    }
  };

  return (
    <Box className="kyb_main_div" p={3} maxWidth="960px" mx="auto">
      <Typography variant="h4" gutterBottom>
        La tua azienda
      </Typography>
      {errorMessage && (
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={12}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </Grid>
        </Grid>
      )}
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : kybCompleted ? (
        <Box textAlign="center" mt={4}>
          <Typography variant="h5" gutterBottom>
            KYB Completed - please login
          </Typography>
          <Button
            onClick={() => navigate('/login')}
            color="primary"
            variant="contained"
          >
            Login
          </Button>
        </Box>
      ) : (
        <form onSubmit={handleFetchCompanyDetails}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="business_number"
                label="Partita IVA"
                value={formData.business_number}
                onChange={handleChange}
                fullWidth
                inputProps={{ maxLength: 11 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="mobile_phone_number"
                label="Numero di Telefono Mobile"
                value={formData.mobile_phone_number}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="country-label"
                  shrink={true}
                >
                  Paese di Residenza
                </InputLabel>
                <Select
                  labelId="country-label"
                  label="Paese di Residenza"
                  name="country_id"
                  value={formData.country_id}
                  onChange={handleChange}
                >
                  {countryOptions.map(option => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                onClick={handleLogout}
                variant="outlined"
                size="small"
                sx={{ mr: 2 }}
              >
                {t('logout')}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="small"
              >
                Cerca
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {apiData && !kybCompleted && (
        <Box mt={3} p={2} border={1} borderRadius={4}>
          <Typography variant="h6">Dettagli dell'azienda</Typography>
          <Typography>CF: {apiData.cf}</Typography>
          <Typography>Denominazione: {apiData.denominazione}</Typography>
          <Typography>PIVA: {apiData.piva}</Typography>
          <Typography>Toponimo: {apiData.toponimo}</Typography>
          <Typography>Via: {apiData.via}</Typography>
          <Typography>Civico: {apiData.civico}</Typography>
          <Typography>Indirizzo: {apiData.indirizzo}</Typography>
          <Typography>Comune: {apiData.comune}</Typography>
          <Typography>Provincia: {apiData.provincia}</Typography>
          <Box mt={2}>
            <Button
              onClick={handlefetchSubmitKybApplication}
              color="secondary"
              variant="contained"
              size="small"
            >
              Conferma
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Kyb;
