import "vanilla-cookieconsent/dist/cookieconsent.css";

export const getCookieConsentConfig = (language) => {
  const acceptAndHide = () => {
    console.log("ludo");
  };
  return {
    guiOptions: {
      consentModal: {
        layout: "box wide inline",
        position: "bottom center",
        flipButtons: false,
        equalWeightButtons: true,
      },
      preferencesModal: {
        layout: "box",
        flipButtons: false,
        equalWeightButtons: true,
      },
    },
    categories: {
      technical: {
        enabled: true,
      },
      preference: {
        enabled: true,
      },
      statistical: {
        enabled: true,
      },
      marketing: {
        enabled: true,
      },
      thirdparty: {
        enabled: true,
      },
    },
    language: {
      default: language,
      translations: {
        it: {
          consentModal: {
            description:
              "Il presente sito Web archivia cookie sul computer dell'utente, che vengono utilizzati per raccogliere informazioni sull'utilizzo del sito e ricordare i comportamenti dell'utente in futuro. I cookie servono a migliorare il sito stesso e a offrire un servizio personalizzato, sia sul sito che tramite altri supporti. Per ulteriori informazioni sui cookie, consultare l'informativa sulla privacy. Se non si accetta l'utilizzo, non verrà tenuta traccia del comportamento durante visita, ma verrà utilizzato un unico cookie nel browser per ricordare che si è scelto di non registrare informazioni sulla navigazione.",
            // acceptAllBtn: "Accetta",
            // acceptNecessaryBtn: "Rifiuta",
            // showPreferencesBtn: "Impostazioni cookie",
            footer: `<div>
            <button
              type="button"
              class="cm__btn"
              data="accept"
            >
            Accetta
            </button>
            <button
              type="button"
              class="cm__btn"
              data="decline"
            >
            Rifiuta
            </button>
            <button
            type="button"
            class="cm__btn cm__btn--secondary"
            data="setting"
          >
          Impostazioni cookie
          </button>
          </div>`,
          },
          preferencesModal: {
            title: "Info cookie",
            acceptAllBtn: "Accetta tutto",
            savePreferencesBtn: "Salva impostazioni",
            sections: [
              {
                description:
                  "Questo sito utilizza i cookie. Utilizziamo i cookie principalmente per migliorare e analizzare la tua esperienza sui nostri siti Web e per scopi di marketing. Poiché rispettiamo il tuo diritto alla privacy, puoi scegliere di non consentire alcuni tipi di cookie. Fai clic sui diversi titoli delle categorie per saperne di più e modificare le impostazioni predefinite. Il blocco di alcuni tipi di cookie può avere un impatto negativo sulla tua esperienza sul sito e limitare i servizi che siamo in grado di fornire.",
              },
              {
                title: "Cookie Tecnici",
                description:
                  "Necessari per il corretto funzionamento del sito. Senza questi cookie, alcune funzionalità potrebbero non essere disponibili.",
                linkedCategory: "technical",
              },
              {
                title: "Cookie di Preferenza",
                description:
                  "Memorizzano le tue preferenze per migliorare la tua esperienza di navigazione.",
                linkedCategory: "preference",
              },
              {
                title: "Cookie Statistici",
                description:
                  "Raccolgono dati anonimi sull’uso del sito per analisi statistiche.",
                linkedCategory: "statistical",
              },
              {
                title: "Cookie di Marketing",
                description:
                  "Utilizzati per mostrarti annunci rilevanti in base ai tuoi interessi.",
                linkedCategory: "marketing",
              },
              {
                title: "Cookie di Terze Parti",
                description:
                  "Utilizzati da fornitori terzi per vari scopi, come descritto di seguito.",
                linkedCategory: "thirdparty",
              },
            ],
          },
        },
        en: {
          consentModal: {
            description:
              "This website stores cookies on your computer, which are used to collect information about how the website is used and to remember your behavior in the future. Cookies are used to improve the website and to offer a personalized service, both on the site and through other media. For further information on cookies, please refer to the privacy policy. If you do not accept the use of cookies, your behavior during your visit will not be tracked, but a single cookie will be used in your browser to remember that you have chosen not to record information about your browsing.",
            // acceptAllBtn: "Accept",
            // acceptNecessaryBtn: "Decline",
            // showPreferencesBtn: "Cookie settings",
            footer: `<div>
            <button
              type="button"
              class="cm__btn"
              data="accept"
            >
            Accept
            </button>
            <button
              type="button"
              class="cm__btn"
              data="decline"
            >
            Decline
            </button>
            <button
            type="button"
            class="cm__btn cm__btn--secondary"
            data="setting"
          >
          Cookie settings
          </button>
          </div>`,
          },
          preferencesModal: {
            title: "Info cookie",
            acceptAllBtn: "Accept all",
            savePreferencesBtn: "Save settings",
            sections: [
              {
                description:
                  "This site uses cookies. We use cookies mainly to improve and analyze your experience on our websites and for marketing purposes. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. Blocking some types of cookies may negatively impact your experience on the site and limit the services we are able to provide.",
              },
              {
                title: "Technical Cookies",
                description:
                  "Necessary for the proper functioning of the site. Without these cookies, some features may not be available.",
                linkedCategory: "technical",
              },
              {
                title: "Preference Cookies",
                description:
                  "These store your preferences to improve your browsing experience.",
                linkedCategory: "preference",
              },
              {
                title: "Statistical Cookies",
                description:
                  "They collect anonymous data on the use of the site for statistical analysis.",
                linkedCategory: "statistical",
              },
              {
                title: "Marketing Cookies",
                description:
                  "Used to show you ads that are relevant to your interests.",
                linkedCategory: "marketing",
              },
              {
                title: "Third-Party Cookies",
                description:
                  "Used by third-party vendors for various purposes, as described below.",
                linkedCategory: "thirdparty",
              },
            ],
          },
        },
      },
    },
  };
};
