// Loading.js or Loading.jsx

import React from 'react';

const Loading = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      zIndex: 1000,
    }}>
      <div style={{
        width: '50px',
        height: '50px',
        border: '5px solid #f3f3f3', // Light grey
        borderTop: '5px solid #E09F3E', // Blue color
        borderRadius: '50%',
        animation: 'spin 2s linear infinite',
      }} />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default Loading;
