import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import { Grid, Card, CardContent, Typography, Button, Box, TextField, TableHead, Table, TableBody, TableCell, TableRow, createTheme, TableSortLabel, } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getCookieValue } from './App';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'; // Sample icon for 'Bank Transfer'
import { PeopleOutline } from '@mui/icons-material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import TablePagination from '@mui/material/TablePagination';
import Loading from './Loading';
import Modal_Deletebeneficiary from './Modal_Deletebeneficiary';
import Modal_Createbeneficiary from './Modal_Createbeneficiary';
import Modal_StartBankTransfer from './Modal_StartBankTransfer';
import { useNavigate } from 'react-router-dom';
import { fetchPageData } from './Util_API_calls'; // Adjust the path as necessary
import { textStyle_small_light, blue_title } from './ReactStyles';
import { t } from './Util_format';

function Page_Banktransfers({ onDataChange, ...props }) {
  let { url_beneficiary_id } = useParams();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint
  const [isLoading, setIsLoading] = useState(true);
  const [isTransfering, setTransfering] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [UserAccountNumberFrom, setUserAccountNumberFrom] = useState('');
  const [UserAccountIDFrom, setUserAccountIDFrom] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isTransferDirect, setIsTransferDirect] = useState(false);
  const [tempBeneficiary, setTempBeneficiary] = useState(null);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState(null);
  const [details] = useState({});
  const [field] = useState('');
  const navigate = useNavigate();

  const [Beneficiary_id_on_url, setBeneficiary_id_on_url] = useState(null);


  const renderSortLabel = (key, label) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
      className='table_header'>
      {label}
    </TableSortLabel>
  );

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const [sortedBeneficiaries, setSortedBeneficiaries] = useState([...beneficiaries]); // Added a new state for the sorted beneficiaries

  // Define a function to handle send money action (this should be implemented accordingly)
  const handleSendMoney = (beneficiary_id) => {
    navigate('/bank-transfers/make-payment/' + beneficiary_id);
  };

  const handleDeleteClick = (beneficiary) => {
    setCurrentBeneficiary(beneficiary);
    setisDeleteModalOpen(true);
  };

  const handleCreateModalOpen = (isDirect) => {
    console.log(isDirect);
    setIsTransferDirect(isDirect);
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setIsCreateModalOpen(false);
  };

  const handleStartTransferModalOpen = (id) => {
    setBeneficiary_id_on_url(id); // Save the ID to state
    setIsTransferModalOpen(true);
  };

  const NavigateToStartTransferModal = () => {
    navigate('/bank-transfers/make-payment/');
  }
  const handleStartTransferModalClose = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setIsTransferModalOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing the number of rows per page
  };

  const [searchQuery, setSearchQuery] = useState('');

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('bank-transfers');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }
      let data = [];
      const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
      switch (RETAIL_OR_BUSINESS) {
        case 'RETAIL':
          data = apidata.data;
          break;
        case 'BUSINESS':
          const business_id = getCookieValue('business_id');
          if (apidata.data.businesses && typeof apidata.data.businesses === 'object') {
            data = apidata.data.businesses[business_id] || null;
            if (!data) {
              console.error(`No business data found for business_id: ${business_id}`);
            }
          } else {
            console.error('Expected apidata.data.businesses to be an object, but it is not');
            data = null; // or handle the case where data is not an object
          }
          break;
      }
      if (onDataChange) {
        onDataChange(data);
      }
      
      setBeneficiaries(data.beneficiaries);
      // console.log();
      setUserAccountNumberFrom(data.user_accounts.current_accounts[0].account_number);
      setUserAccountIDFrom(data.user_accounts.current_accounts[0].account_id);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  const handleCloseTransferModal = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setIsTransferModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsTransferDirect(false);
    setTempBeneficiary(null);
    setisDeleteModalOpen(false);
  }

  const onTransferDirect = (beneficiary) => {
    setTempBeneficiary(beneficiary);
    setIsCreateModalOpen(false);
    setIsTransferModalOpen(true);
  }

  useEffect(() => {
    // Check if the current path starts with '/bank-transfers/create-beneficiary'
    if (location.pathname.startsWith('/bank-transfers/create-beneficiary')) {
      handleCreateModalOpen(false);
    } else if (location.pathname.startsWith('/bank-transfers/new')) {
      handleCreateModalOpen(true);
    }
    // Check if the current path starts with '/bank-transfers/make-payment'
    if (location.pathname.startsWith('/bank-transfers/make-payment')) {
      const pathSegments = location.pathname.split('/');
      const url_beneficiary_id = pathSegments[3]; // Gets the ID from the path
      handleStartTransferModalOpen(url_beneficiary_id);
    }
  }, [location]); // Depend on the location object so that the effect runs on location change 

  useEffect(() => {
    // This effect will run whenever the sortConfig changes
    function sortList(beneficiaries) {
      return [...beneficiaries].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    // Set the sorted beneficiaries state with the newly sorted array
    setSortedBeneficiaries(sortList(beneficiaries));
  }, [sortConfig, beneficiaries]);
  useEffect(() => {
    function sortAndFilterList(beneficiaries) {
      return beneficiaries
        .filter(beneficiary => {
          return (
            beneficiary.beneficiary_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            beneficiary.beneficiary_iban.toLowerCase().includes(searchQuery.toLowerCase()) ||
            beneficiary.beneficiary_surname.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
    }

    // Set the sorted beneficiaries state with the newly sorted and filtered array
    setSortedBeneficiaries(sortAndFilterList(beneficiaries));
  }, [sortConfig, beneficiaries, searchQuery]); // Added searchQuery to the dependency array

  useEffect(() => {
    get_page_data();

  }, []);

  function DeletebeneficiaryDialog({ beneficiary, isOpen, onClose, onSave }) {
    const [value, setValue] = useState(details[field] || '');
    useEffect(() => {
      setValue(details[field] || '');
    }, [field, details]);

    if (!isOpen || !beneficiary) {
      return null;
    }
    return (
      <Modal_Deletebeneficiary
        beneficiary={beneficiary}
        onDelete={() => {
          onClose(); // Close the modal after deletion
        }}
        onClose={onClose}
        closeDeleteModal={handleCloseDeleteModal}
      />
    );
  }

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <>
      {isCreateModalOpen && (
        <Modal_Createbeneficiary
          // ... other props
          isOpen={isCreateModalOpen}
          onClose={() => handleCreateModalClose()}
          isTransferDirect={isTransferDirect}
          onTransferDirect={onTransferDirect}
        />
      )}

      {isDeleteModalOpen && (
        <DeletebeneficiaryDialog
          beneficiary={currentBeneficiary}
          isOpen={isDeleteModalOpen}
          onClose={() => setisDeleteModalOpen(false)}
          closeDeleteModal={handleCloseDeleteModal}
        />
      )}
      {isTransferModalOpen && (
        <Modal_StartBankTransfer
          isOpen={isTransferModalOpen}
          onClose={isTransfering ? null : handleStartTransferModalClose}
          beneficiaries={beneficiaries}
          url_beneficiary_id={url_beneficiary_id}
          closeModal={handleCloseTransferModal}
          UserAccountNumberFrom={UserAccountNumberFrom}
          UserAccountIDFrom={UserAccountIDFrom}
          tempBeneficiary={tempBeneficiary}
          setTransfering={setTransfering}
        />
      )}
      <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1000px' }}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12}>
          </Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', }}>
              <CardContent style={{ paddingBottom: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                    {t("bank_transfers")}
                  </Typography>
                  <TransferWithinAStationIcon  color="primary"/>
                </div>
                <div style={{ width: '100%', alignItems: 'center' }}>
                  <Button
                    style={{
                      height: '40px',
                      width: isMobile ? '100%' : 'auto',
                      marginRight: '20px'
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<PersonOutlineIcon />}
                    fullWidth={isMobile}
                    onClick={() => handleCreateModalOpen(false)} // <-- Add this line
                  >
                    {t("create_beneficiary")}
                  </Button>

                  <Button
                    style={{
                      height: '40px',
                      width: isMobile ? '100%' : 'auto',
                       marginRight: '20px'
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SendIcon />}
                    fullWidth={isMobile}
                    onClick={() => handleCreateModalOpen(true)} // Here is the change
                  >
                    {t("new_bank_transfer")}
                  </Button>              
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '30px' }}>
                    <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                      {t("saved_beneficiaries")}
                    </Typography>
                    <PeopleOutline  color="primary"/>
                  </div>

                  <TextField
                    placeholder={t("search_beneficiary")}
                    variant="outlined"
                    style={{ margin: '20px 0', maxWidth: '900px' }} // adjust styling as needed
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  <Table style={{ maxWidth: '900px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{renderSortLabel('name', t('beneficiary_name'))}</TableCell>
                        {/* Conditional rendering based on isMobile flag for specific columns */}
                        {!isMobile && <TableCell>{renderSortLabel('iban', t('beneficiary_iban'))}</TableCell>}
                        {!isMobile && <TableCell>{renderSortLabel('currency', t('currency'))}</TableCell>}
                        {/* Added a new column for operations */}
                        <TableCell>{renderSortLabel('operations', t('operations'))}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedBeneficiaries
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((beneficiary, index) => (
                          <TableRow key={index}>
                            <TableCell className="table_body">{beneficiary.beneficiary_name} {beneficiary.beneficiary_surname}</TableCell>
                            {/* Conditional rendering based on isMobile flag for specific cells */}
                            {!isMobile && <TableCell className="table_body">{beneficiary.beneficiary_iban.match(/.{1,4}/g).join(' ')}</TableCell>}
                            {!isMobile && <TableCell className="table_body">{beneficiary.beneficiary_currency}</TableCell>}
                            {/* Operations cell with action buttons */}
                            <TableCell className="table_body">
                              {/* Edit action
                              <IconButton onClick={() => handleEdit(beneficiary.beneficiary_id)} color="primary" className="icon-edit">
                                <EditIcon />
                              </IconButton>*/}
                              {/* Delete action */}
                              <IconButton onClick={() => handleDeleteClick(beneficiary)} color="primary" className="icon-delete">
                                <DeleteIcon />
                              </IconButton>

                              {/* Send money action */}
                              <IconButton onClick={() => handleSendMoney(beneficiary.beneficiary_id)} color="primary">
                                <SendIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sortedBeneficiaries.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('rows_per_page')}
                    labelDisplayedRows={
                      ({ from, to, count }) => {
                        return '' + from + '-' + to + ` ${t('of')} ` + count
                      }
                    }
                    className='table_bottom'
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Page_Banktransfers;