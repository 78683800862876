// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { t } from './Util_format';
import React, { useEffect, useRef } from 'react';


export default function Page_Contactus() {

  // const iframeUrl = "https://guidautente.cartareale.it/it/knowledge/kb-tickets/new";

  const boxRef = useRef(null);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script1.class = "custom-contact-iframe-box";
    script1.charset = "utf-8";
    script1.type = "text/javascript";
    script1.onload = () => {
      if (window.hbspt && typeof window.hbspt.forms === 'object' && typeof window.hbspt.forms.create === 'function') {
        window.hbspt.forms.create({
          target: `#${boxRef.current.id}`,
          region: "eu1",
          portalId: "25343058",
          formId: "297b08a6-ef50-4b03-bded-d0eeb25b121f"
        });
      }
    };
    document.body.appendChild(script1);

    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  return (
    <Container   sx={{paddingY:"80px"}}>
      <Typography variant="h4" sx={{ color: "#333333", fontFamily: 'Poppins'}}> 
      { t('open_a_ticket') } 

      </Typography>
      <Box
        ref={boxRef}
        id="hubspot-box"
        sx={{
          padding:"30px",
          mt: 5,
          width: 1,
          height: 320,
          borderRadius: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `dashed 2px ${theme.palette.divider}`,
        }}
      />
    </Container>
  );
}
