import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Button, Drawer, Icon, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Modal, TextField, Toolbar, Typography, createTheme, ThemeProvider, styled, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { getCookieValue, changeLanguage, setCookieValue } from './App';
import logopng from './pics/logo.png';
import flag_it from './pics/flag_it.svg';
import flag_en from './pics/flag_en.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MailIcon from '@mui/icons-material/Mail';
import { t } from './Util_format';
import Modal_ReadMessage from './Modal_ReadMessage';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Icon for unread mail
import { markMessageAsRead } from './Util_API_calls'; // Adjust the path as necessary
import config from './config/env.json';
import { theme } from './ReactStyles';
import { useIntercom } from 'react-use-intercom';

function Structure_logged({ children, APIPageData = {} }) {
  const { shutdown } = useIntercom();
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerReduced, setDrawerReduced] = useState(false);
  const [open, setOpen] = useState(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const currentFlag = getCookieValue('language') === 'en' ? flag_en : flag_it;

  // Use useEffect to update the email state when APIPageData.details.email is available
  const [user_name_surname, setuser_name_surname] = useState('');
  const [user_email, setuser_email] = useState('');

  useEffect(() => {
    if (APIPageData && APIPageData.details) {
      if (APIPageData.details.first_name && APIPageData.details.last_name) {
        setuser_name_surname(APIPageData.details.first_name + ' ' + APIPageData.details.last_name);
      }
      if (APIPageData.details.email) {
        setuser_email(APIPageData.details.email);
      }
    }
  }, [APIPageData]);

  // check if maintenance mode is on
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (config.VERIFY_MAINTENANCE_MODE !== 'true') {
        // console.error('NO MAINTENANCE MODE CHECK');
        return;
      }
      fetch(config.PUBLIC_BUCKET_NAME + '/systems_health/api.txt')
        .then((response) => {
          // Check if the file was not found and exit early if so
          if (response.status === 404) {
            console.log('Maintenance mode file not found. Doing nothing.');
            return;
          }
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then((data) => {
          // If there's no data, do nothing
          if (!data) return;

          const lines = data.trim().split('\n'); // Trimming and splitting by new line
          const startTime = new Date(lines[0] + 'Z'); // Adding 'Z' to indicate UTC
          const endTime = new Date(lines[1] + 'Z'); // Adding 'Z' to indicate UTC
          const currentTime = new Date(); // Current time in UTC

          // console.log(`START: ${startTime.toISOString()} UTC`);
          // console.log(`END: ${endTime.toISOString()} UTC`);
          // console.log(`NOW: ${currentTime.toISOString()} UTC`);

          // Check if current time is within the maintenance window
          if (currentTime >= startTime && currentTime <= endTime) {
            navigate('/maintenance');
            console.log('We are currently in the middle of a maintenance outage.');
          } else {
            // console.log('We are not currently in a maintenance outage.');
          }
        })
        .catch((error) => {
          if (error.message.includes('404')) {
            // File not found, do nothing
          } else {
            console.error('Failed to fetch data:', error);
          }
        });
    }, 5000);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);


  const handleLanguageChange = (lang) => {
    handleClose(); // Close the menu
    changeLanguage(lang); // Change the language
  };

  const handleCloseMessageModal = () => {
    setIsMessagesModalOpen(false);
  };


  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {

    // This will safely handle the case when APIPageData is null or undefined
    const count = APIPageData?.messages
      ? APIPageData.messages.filter(msg => !msg.is_read).length
      : 0;

    setUnreadMessagesCount(count);
  }, [APIPageData]); // Dep

  const ListItemTextCickable = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
      fontWeight: '500',
      color: '#637381',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      textOverflow: 'ellipsis',
    },
    '&.reduced-text .MuiListItemText-primary': { // When reduced
      fontSize: '0.7rem', // Smaller font
      textAlign: 'center', // Corrected here
    },
  }));

  const handleLangMenuClick = (event) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleLogout = () => {
    shutdown();
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  const handleGoToProfile = () => {
    navigate('/profile');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleGoToHome = () => {
    navigate('/home');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    alignContent: 'center',  // Center the icon
    minWidth: drawerReduced ? 'unset' : undefined,  // Remove the default minWidth only when reduced
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '5px',
    transition: 'background-color 0.3s ease',
    flexDirection: drawerReduced ? 'column' : 'row',  // Vertical stack when reduced
    justifyContent: drawerReduced ? 'center' : 'flex-start',  // Centered when reduced
    alignItems: 'center',  // Horizontally center
    '&:hover': {
      backgroundColor: '#edefef',
    },
    '&:active': {
      backgroundColor: '#c5cacf',
    },
  }));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const TitleListItem = styled(ListItem)(({ theme }) => ({
    pointerEvents: 'none',
    userSelect: 'none',
    fontSize: '0.8rem',
    paddingBottom: '10px',
  }));



  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint

  const contentStyles = isMobile
    ? {
      marginLeft: 0,
      width: 'calc(100vw - 40px)',
      paddingLeft: '20px',
      paddingRight: '20px',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
      justifyContent: 'center !important',
      textAlign: 'center !important',
    }
    : {
      marginLeft: drawerReduced ? 100 : 260,
      width: drawerReduced ? 'calc(100vw - 140px)' : 'calc(100vw - 300px)',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto', // fixed property name
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      paddingTop: '0px',
      paddingLeft: '20px',
      paddingRight: '20px'
    };

  // console.log('APIPageData', APIPageData.details);



  // if the cookie is not present, redirect to the login page
  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
      navigate('/login');
    } else {
      const loginOtpCompleted = getCookieValue('login_otp_completed');
      if (loginOtpCompleted !== 'true') {
        const isPushMessage = getCookieValue('otp_delivery_method') === "push";
        if (isPushMessage) {
          navigate("/waiting_push");
        } else {
          navigate("/login_otp");
        }
      }
    }
  }, []);

  const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({ msg_id: null, subject: '', content: '', created_at: '' });

  // Function to open the modal
  const openMessagesModal = () => {
    setIsMessagesModalOpen(true);
  };

  // Function to close the modal
  const closeMessagesModal = () => {
    setIsMessagesModalOpen(false);
  };

  const handleReadMessage = (message) => {
    markMessageAsRead(message.msg_id);

    // Update the is_read property in the original APIPageData.messages array
    const updatedMessages = APIPageData.messages.map(msg =>
      msg.msg_id === message.msg_id ? { ...msg, is_read: true } : msg
    );

    // Directly update APIPageData.messages with the new values
    APIPageData.messages = updatedMessages;

    setCurrentMessage({
      msg_id: message.msg_id,
      subject: message.subject,
      content: message.content,
      created_at: message.created_at
    });

    // Calculate new unread messages count
    const newUnreadCount = updatedMessages.filter(msg => !msg.is_read).length;
    setUnreadMessagesCount(newUnreadCount);

    openMessagesModal(); // Open the modal
  };


  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <div id="page_dashboard" className="page_dashboard">
      <ThemeProvider theme={theme}>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerToggle}
            style={{
              position: 'absolute',
              backdropFilter: 'blur(10px)',
              top: '18px',
              left: '20px',
              zIndex: 1201
            }}
          >
            <MenuIcon />
          </IconButton>

        )}
        {!isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setDrawerReduced(!drawerReduced)}
            style={{
              position: 'absolute',
              top: '40px',
              transform: 'translateY(-50%) scale(0.5)',
              left: drawerReduced ? '90px' : '250px',
              border: '1px dotted #AAAAAA',
              borderRadius: '50%',
              width: '48px !important',
              height: '48px !important',
              zIndex: 1202,
              backgroundColor: '#FEFEFE',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {drawerReduced ?
              <ArrowForwardIcon style={{ fontSize: '24px' }} /> : <ArrowBackIcon style={{ fontSize: '24px' }} />
            }
          </IconButton>
        )}
        <AppBar position="static" style={{
          marginLeft: isMobile ? 0 : (drawerReduced ? 100 : 260),
          width: isMobile ? 'calc(100vw)' : (drawerReduced ? 'calc(100vw - 100px)' : 'calc(100vw - 260px)'),
          paddingLeft: isMobile ? 60 : (drawerReduced ? 0 : 0)
        }}>
          <Toolbar style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '80px',
          }}
          >
            <IconButton
              style={{ visibility: 'hidden' }} // Hide the button
            >
              <SearchIcon />
            </IconButton>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              {/* Accounts Menu */}
              <div id="account-in-use" style={{ display: 'flex', alignItems: 'center' }}></div>
              {/* Language Menu */}
              <IconButton onClick={handleLangMenuClick}>
                <img src={currentFlag} alt="Italiano" style={{ width: '28px', height: '28px', borderRadius: '10px' }} />
              </IconButton>
              <Menu
                anchorEl={langMenuAnchorEl}
                keepMounted
                open={Boolean(langMenuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    marginTop: '10px'
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {[
                  { flag: flag_it, alt: "Italiano", lang: "it" },
                  { flag: flag_en, alt: "English", lang: "en" }
                ].map(item => (
                  <MenuItem
                    key={item.lang}
                    onClick={() => handleLanguageChange(item.lang)}
                    style={{
                      fontWeight: '500',
                      color: '#637381',
                      fontSize: '0.875rem',
                      fontFamily: 'Poppins',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <img src={item.flag} alt={item.alt} style={{ width: '28px', height: '20px', marginRight: '10px' }} />
                    {item.alt}
                  </MenuItem>
                ))}
              </Menu>

              {/* Notifications */}
              <IconButton onClick={toggleDrawer(true)}>
                <Badge badgeContent={unreadMessagesCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <div style={{ padding: '20px' }}>
                    <Typography className='notifications_title'>
                      {t('notifications')}
                    </Typography>
                    {
                      APIPageData?.messages && APIPageData.messages.length > 0 ? (
                        APIPageData.messages
                          .slice() // Create a shallow copy of the messages array to avoid mutating the original array.
                          .sort((a, b) => {
                            // Parse dates and compare them.
                            const dateA = new Date(a.created_at);
                            const dateB = new Date(b.created_at);
                            return dateB - dateA; // Sort descending: newer dates first
                          })
                          .map((message, index) => {
                            // Render each message with appropriate styling based on read status
                            return (
                              <div
                                className="notifications_list_with_border"
                                key={message.id || index} // Using message.id if available, otherwise fall back to index
                                onClick={() => handleReadMessage(message)}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: message.is_read ? '#777777' : '#333333',
                                }}
                              >
                                <Icon style={{ color: message.is_read ? '#f0f0f0' : '#333333', marginRight: '8px' }}>
                                  {message.is_read ? <MailIcon /> : <MailOutlineIcon />}
                                </Icon>
                                {message.subject}
                              </div>
                            );
                          })
                      ) : (
                        <Typography className='no_notifications'>
                          {t('no_notifications')}
                        </Typography>
                      )
                    }
                  </div>
                </div>
              </Drawer>

              {/* Account Menu */}
              <IconButton onClick={handleAccountMenuClick}>
                <AccountCircleIcon style={{ width: '28px', height: '28px', borderRadius: '5px' }} color="primary" />
              </IconButton>
              <Menu
                anchorEl={accountMenuAnchorEl}
                keepMounted
                open={Boolean(accountMenuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    marginTop: '10px'
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={handleGoToHome} style={{
                  fontWeight: '500',
                  color: '#637381',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  textOverflow: 'ellipsis',
                }}
                >
                  <HomeIcon color="primary" style={{ paddingRight: '10px' }} />
                  {t("home")}
                </MenuItem>
                <MenuItem onClick={handleGoToProfile} style={{
                  fontWeight: '500',
                  color: '#637381',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  textOverflow: 'ellipsis'
                }}
                >
                  <AccountCircle color="primary" style={{ paddingRight: '10px' }} />
                  {t("profile")}
                </MenuItem>
                <MenuItem onClick={handleLogout}
                  style={{
                    fontWeight: '500',
                    color: '#637381',
                    fontSize: '0.875rem',
                    fontFamily: 'Poppins',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <LogoutIcon color="primary" style={{ paddingRight: '10px' }} />
                  {t("logout")}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>

        </AppBar>
        <div style={{ ...contentStyles }}>
          {children}
        </div>

        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? open : true}
          onClose={handleDrawerToggle}
          className={drawerReduced ? "reduced-drawer" : ""}
          classes={{
            paper: drawerReduced ? "reduced-drawer" : "",
          }}
          style={{ padding: '5px' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>

              <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
                <List>
                  <ListItem
                    style={{
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      userSelect: 'none',
                      pointerEvents: 'auto', // Enable pointer events
                      backgroundColor: 'transparent', // Set default background color
                      '&:hover': {
                        backgroundColor: 'transparent' // Keep background color transparent on hover
                      }
                    }}
                  >
                    <img
                      src={logopng}
                      alt="Company Logo"
                      style={{
                        width: '40px',
                        height: '68px',
                        cursor: 'pointer' // Ensure cursor changes to pointer on hover
                      }}
                      onClick={handleLogoClick} // Attach the click handler
                    />
                  </ListItem>


                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary="CARTA REALE"
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#333333', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>
                  <StyledListItem
                    onClick={() => { navigate('/home'); }}
                    style={location.pathname.startsWith('/home') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><HomeIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("home")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("home")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/profile'); }}
                    style={location.pathname.startsWith('/profile') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><PersonIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("profile")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("profile")} />
                    )}
                  </StyledListItem>


                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary={t('my_bank')}
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#333333', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>

                  <StyledListItem
                    onClick={() => { navigate('/cards'); }}
                    style={location.pathname.startsWith('/cards') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><PaymentIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("prepaid_cards")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("prepaid_cards")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/bank-transfers'); }}
                    style={location.pathname.startsWith('/bank-transfers') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><TransferWithinAStationIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("bank_transfers")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("bank_transfers")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/services'); }}
                    style={location.pathname.startsWith('/services') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><ShoppingCartIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("services")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("services")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/transactions'); }}
                    style={location.pathname.startsWith('/transactions') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><DescriptionIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("bank_transactions")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("bank_transactions")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/statements'); }}
                    style={location.pathname.startsWith('/statements') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><PictureAsPdfIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("bank_statements")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("bank_statements")} />
                    )}
                  </StyledListItem>

                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary={t('customer_support')}
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#333333', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>

                  <StyledListItem
                    component="a"
                    href="https://guidautente.cartareale.it/it/knowledge"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemIconStyled><HelpIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("user_guide")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("user_guide")} />
                    )}
                  </StyledListItem>

                </List>
              </div>
            </div>

          </div>
        </Drawer>
        {isMessagesModalOpen &&
          <Modal_ReadMessage
            onClose={closeMessagesModal}
            closeModal={handleCloseMessageModal}
            msg_id={currentMessage.msg_id}
            subject={currentMessage.subject}
            content={currentMessage.content}
            created_at={currentMessage.created_at}
          />
        }
      </ThemeProvider>        </div>
  );
}

export default Structure_logged;