import React from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';

function Page_maintenance() {

  const goHome = () => {
    navigate('/');
  };

  const navigate = useNavigate();

  
  // Assume 'maintenance_title', 'maintenance_message', 'apology_message', and 'thank_you_message' are keys in your translations file
  // and 'go_home' is the translation key for the button text.
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>{t('maintenance_title')}</h1>
      <p>{t('maintenance_message')}</p> 
      <p>{t('apology_message')}</p> 
      <p>{t('thank_you_message')}</p> 
      <button onClick={goHome} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '18px', cursor: 'pointer' }}>
        {t('go_home')} 
      </button>
    </div>
  );
}

export default Page_maintenance;
