import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { t } from './Util_format';

function Modal_ReadMessage({ closeModal, onClose, msg_id, subject, created_at, content }) {
  // Convert the ISO string to a Date object
  const date = new Date(created_at);

  // Format the date to Italian date format
  const formattedDate = date.toLocaleString('it-IT', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
  
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className='modal_title'>
        {subject}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ paddingBottom: '20px' }}>
          {formattedDate}
        </Typography>
        <Typography>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal()}
          variant="contained"
          color="primary"
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_ReadMessage;