import React, { useState, useEffect, } from 'react';
import { Grid, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, createTheme, ThemeProvider, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, Divider, } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getCookieValue } from './App';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import pdf_icon from './pics/pdf_icon.svg';
import { it } from 'date-fns/locale';
import { fetchPageData, fetchCardTransactions, fetchBankAccountTransactions } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import { number_to_italian_currency } from './Util_format';
import Modal_TransactionDetails from './Modal_TransactionDetails';
import { useNavigate } from 'react-router-dom';
import { textStyle_small_light, blue_title, iconStyle, menuItemStyle, theme } from './ReactStyles';
import { generatePDF } from './Util_generatePDF';
import { isCreditTransaction, isNOOPTransaction } from './Util_app';

function TransactionsTable({ transactions, onRowClick, onPageChange, dateRange, searchTerm, account }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMonthYear, setSelectedMonthYear] = useState(dayjs().format('YYYY-MM'));

  // Check if an account is selected and transactions is an array and not empty
  if (!account) {
    return (
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        {t('please_select_the_account')}
      </Typography>
    );
  } else if (!Array.isArray(transactions) || transactions.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_transactions_to_show')}
      </Typography>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy hh:mm:ss', { locale: it }) : 'Invalid Date';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = transactions.sort((a, b) => {
    const dateA = new Date(a.transaction_date_time);
    const dateB = new Date(b.transaction_date_time);
    return dateB - dateA; // This will sort in descending order
  });


  const filteredTransactions = sortedTransactions.filter(transaction => {
    // Determine the correct date field based on the transaction type
    const transactionDateField = 'bank_transfer_id' in transaction ? 'transaction_date_time' : 'transaction_created_at';
    const transactionDate = new Date(transaction[transactionDateField]);

    if (transactionDate < dateRange.from || transactionDate > dateRange.to) {
      return false;
    }

    // Determine the correct description field based on the transaction type
    const transactionDescription = 'bank_transfer_id' in transaction
      ? transaction.description?.toLowerCase() ?? ''
      : transaction.merchant_name ? (t(transaction.transaction_type) + ' @ ' + transaction.merchant_name).toLowerCase() : t(transaction.transaction_type).toLowerCase();

    return transactionDescription.includes(searchTerm.toLowerCase());
  });



  const displayedTransactions = filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
          <TableCell>{t('date')}</TableCell>
            <TableCell>{t('description')}</TableCell>
            <TableCell align="right">{t('amount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedTransactions.map((transaction) => {
            // Determine the correct date field for display
            const transactionDateField = 'bank_transfer_id' in transaction ? 'transaction_date_time' : 'transaction_created_at';

            // Determine the correct description for display
            const transactionDescription = 'bank_transfer_id' in transaction
              ? transaction.description
              : transaction.merchant_name ? t(transaction.transaction_type) + ' @ ' + transaction.merchant_name : t(transaction.transaction_type);

            return (
              <TableRow key={transaction.transaction_id} onClick={() => onRowClick(transaction)}>
                <TableCell className="table_body" style={{ cursor: 'pointer' }}>
                  {formatDate(transaction[transactionDateField])}
                </TableCell>
                <TableCell className="table_body" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  {transactionDescription} {/* Display the correct description */}
                  {transaction.status === 'PENDING' && <AccessTimeIcon style={{ marginLeft: '5px', color: '#CCCCCC' }} />}
                </TableCell>
                {transaction.status === "REJECTED" ||
                isNOOPTransaction(transaction) || transaction.transaction_financial_status === "ON_HOLD" ? (
                  <TableCell className="table_body" align="right">
                    {transaction.status === "REJECTED"
                      ? t("cancelled")
                      : number_to_italian_currency(transaction.amount)}
                  </TableCell>
                ) : (
                  <TableCell className={`table_body ${isCreditTransaction(transaction) ? "inward_money" : "outward_money"}`} align="right">
                    {number_to_italian_currency(transaction.amount)}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>

      </Table>
      <TablePagination
        style={{
          marginTop: '20px'
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('rows_per_page')}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ` ${t('of')} ` + count
          }
        }
        className='table_bottom'
      />
    </div>
  );
}

function Page_Statements({ onDataChange, ...props }) {
  const [account, setAccount] = React.useState('');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [bankAccountID, setbankAccountID] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const navigate = useNavigate();
  const [sortedTransactions, setSortedTransactions] = useState([]);
  const [accountType, setAccountType] = useState(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(dayjs().format('YYYY-MM'));

  const [currentPage, setCurrentPage] = useState(0);
  // Other state and variables
  const [dateRange, setDateRange] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const handleDateChange = (name) => (newValue) => {
    setDateRange({ ...dateRange, [name]: newValue });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleShowTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const [userAccounts, setUserAccounts] = useState([]);

  const generateMonthYearOptions = () => {
    let options = [];
    let current = dayjs().startOf('month');
    for (let i = 0; i < 12; i++) { // Assuming last 12 months
      options.push(current.format('YYYY-MM'));
      current = current.subtract(1, 'month');
    }
    return options;
  };

  // Inside the get_page_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts.map(account => ({
      name: `${t('card_label')} **** **** **** ${account.card_last_four_digits}`, // Masked PAN
      card_id: `${account.card_id}`,
      type: 'card',
      info: account.card_last_four_digits
    }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `${t('bank_accounts')} ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account',
      info: account.account_number
    }));


    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  useEffect(() => {
    get_page_data();
  }, []);

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const accountIcon = (type) => {
    return type === 'account' ?
      <AccountBalanceIcon style={iconStyle} /> :
      <CreditCardIcon style={iconStyle} />;
  };

  const getSelectedAccountType = () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    return selectedAccount ? selectedAccount.type : null;
  };

  useEffect(() => {
    if (account) {
      const accountType = getSelectedAccountType();
      setAccountType(accountType);  
      if (accountType === 'card') {
        get_card_data();
      } else if (accountType === 'account') {
        get_bank_account_data();
      }
    }
  }, [account, dateRange]);


  useEffect(() => {
    const start = startOfMonth(new Date(selectedMonthYear));
    const end = endOfMonth(new Date(selectedMonthYear));

    setDateRange({
      from: start,
      to: end
    });
  }, [selectedMonthYear]);


  const get_card_data = async () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for card data fetch");
      return;
    }

    const formattedFromDate = format(dateRange.from, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'
    const formattedToDate = format(dateRange.to, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'

    setIsLoading(true);
    try {
      const data = await fetchCardTransactions(
        selectedAccount.card_id,
        'ALL',
        formattedFromDate,
        formattedToDate,
        'CET'
      );

      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching card data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false);
    }
  };


  const get_bank_account_data = async () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for bank account data fetch");
      return;
    }

    const formattedFromDate = format(dateRange.from, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'
    const formattedToDate = format(dateRange.to, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'

    setIsLoading(true);
    try {
      const data = await fetchBankAccountTransactions(
        selectedAccount.account_id,
        'ALL',
        formattedFromDate,
        formattedToDate,
        'CET'
      );

      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching bank account data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false);
    }
  };


  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('statements');

      let data = [];
      const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
      switch (RETAIL_OR_BUSINESS) {
        case 'RETAIL':
          data = apidata.data;
          break;
        case 'BUSINESS':
          const business_id = getCookieValue('business_id');
          if (apidata.data.businesses && typeof apidata.data.businesses === 'object') {
            data = apidata.data.businesses[business_id] || null;
            if (!data) {
              console.error(`No business data found for business_id: ${business_id}`);
            }
          } else {
            console.error('Expected apidata.data.businesses to be an object, but it is not');
            data = null; // or handle the case where data is not an object
          }
          break;
      }


      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }

      if (onDataChange) {
        onDataChange(data);
      }
      if (data) {
        setPageData(data);
        // Call processAccountsData here to process and set user accounts
        processAccountsData(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  

  const formatDateSelect = (dateStr) => {
    const [year, month] = dateStr.split("-");
    const date = new Date(year, month - 1); // Months are 0-indexed in JavaScript Date
    const currentLanguage = getCookieValue("language");
    const formattedDate = date.toLocaleString(currentLanguage, { month: 'long', year: 'numeric' });
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      {isModalOpen && (
        <Modal_TransactionDetails
          transaction={selectedTransaction}
          onClose={handleCloseModal}
          pageData={pageData}
          info={userAccounts.find(acc => acc.name === account)?.info}
          isCardTransaction={accountType === 'card'}
        />
      )}
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          {/* This Grid item appears to be empty. If not needed, consider removing it. */}
          <Grid item xs={12}></Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                    {t('bank_statements')}
                  </Typography>
                  <PictureAsPdfIcon color="primary" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px' }}>
                  <FormControl style={{ marginRight: '10px', width: '450px' }}>
                    <InputLabel id="account-select-label" className="transactions_input-label">
                      {t('select_account')}
                    </InputLabel>
                    <Select
                      labelId="account-select-label"
                      id="account-select"
                      value={account}
                      label={t('select_account')}
                      onChange={handleChange}
                      color="primary"
                      IconComponent={ListIcon}
                      className="transactions_select"
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          {userAccounts.find((item) => item.name === selected) ? accountIcon(userAccounts.find((item) => item.name === selected).type) : null}
                          <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                            {selected}
                          </Typography>
                        </Box>
                      )}
                    >
                      {userAccounts.map((item) => (
                        <MenuItem key={item.name} value={item.name} style={menuItemStyle}>
                          <ListItemIcon>
                            {accountIcon(item.type)}
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ flexGrow: 2, marginRight: '10px' }}
                    name="search_filter_unique"
                    label={t('search_filter')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="no-autofill"
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                    <FormControl style={{ width: '200px', marginRight: '10px' }}>
                      <InputLabel id="month-year-select-label">{t('select_statement')}</InputLabel>
                      <Select
                        labelId="month-year-select-label"
                        id="month-year-select"
                        value={selectedMonthYear}
                        onChange={(e) => setSelectedMonthYear(e.target.value)}
                        label={t('select_statement')}
                      >
                        {generateMonthYearOptions().map((option) => (
                          <MenuItem key={option} value={option}>{formatDateSelect(option)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </LocalizationProvider>

                  {/* Styled Export to PDF button */}
                  <button
                    onClick={() => generatePDF(accountType, transactions, { account, searchTerm, dateRange }, sortedTransactions, selectedMonthYear, pageData, userAccounts.find(acc => acc.name === account)?.info)}
                    style={{
                      paddingLeft: '5px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: 'transparent',
                    }}
                    aria-label="Export to PDF" // Accessibility label
                  >
                    <img src={pdf_icon} alt="PDF" style={{ height: '30px' }} />
                  </button>


                </div>
                {isLoading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (
                  <div>
                    {/* Add this button and pass the transactions state to the generatePDF function */}
                    <TransactionsTable
                      key={searchTerm}
                      transactions={transactions}
                      onPageChange={handlePageChange}
                      dateRange={dateRange}
                      searchTerm={searchTerm}
                      account={account}
                      onRowClick={handleShowTransaction}
                      ongeneratePDF={() => generatePDF(accountType,transactions, { account, searchTerm, dateRange }, sortedTransactions, selectedMonthYear, pageData, userAccounts.find(acc => acc.name === account)?.info)}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Statements;