import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, Typography, createTheme } from '@mui/material';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import translations from './config/translations.json';
import firebaseConfig from './config/firebase_config.json';
import config from './config/env.json';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieValue, setCookieValue, deleteCookie } from './App';
import { t } from './Util_format';
import { theme } from './ReactStyles';
import { passwordRegex } from './utils/Validator';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useIntercom } from 'react-use-intercom';
import { get_api_url } from './Util_API_calls';

// Initialize Firebase outside the component to ensure it's only done once
const app = initializeApp(firebaseConfig);

function Modal_UpdatePassword({ onSave, onClose }) {
  const { shutdown } = useIntercom();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const language = getCookieValue('language') || 'it';  // Default to 'it' if the cookie is not set

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };

  const handleUpdatePassword = async () => {

    // handle firebase no_user_signed_in error
    if (oldPassword === '' || newPassword === '' || confirmNewPassword === '') {
      setErrorMessage(t('password_empty'));
      return;
    }

    if (!validatePassword(newPassword)) {
      setErrorMessage(t('password_complexity_error'));
      return;
    }



    if (newPassword !== confirmNewPassword) {
      setErrorMessage(t('passwords_do_not_match'));
      return;
    }

    setIsProcessing(true);
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (!user) {
      shutdown();
      navigate('/login?action=clean-cookie&reason=user_not_found');
      return;
    }

    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      await sendEmailConfirmation(); // Ensures email is sent after password update
      navigate("/login?message=password_update_success");
    } catch (error) {
      // Check for the specific error code
      if (error.code === 'auth/invalid-login-credentials') {
        setErrorMessage(t('invalid_login_credentials'));
      } else {
        // Handle other errors generally
        setErrorMessage(t(error.code) || error.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const sendEmailConfirmation = async () => {
    const token = getCookieValue('firebaseToken');
    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/user/send_email_password_updated`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setCookieValue('EmailDoubleAuthExp', new Date(Date.now() + 10 * 60000));
    } catch (error) {
      setErrorMessage(t(error.code) || error.message);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className='modal_title'>{t('update_password_title')}</DialogTitle>
      {errorMessage && (
        <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
          <div className="error-container">
            <div className="error-icon">!</div>
            <Typography className="error-text">{errorMessage}</Typography>
          </div>
        </div>
      )}
      <DialogContent style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <TextField
          margin="dense"
          label={t('old_password')}
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          disabled={isProcessing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            )
          }}
        />
        <TextField
          margin="dense"
          label={t('new_password')}
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={isProcessing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            )
          }}
        />
        <TextField
          margin="dense"
          label={t('confirm_new_password')}
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          disabled={isProcessing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            )
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isProcessing}>
          {t('cancel')}
        </Button>
        <Button
          onClick={handleUpdatePassword}
          variant="contained"
          color="primary"
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} /> : t('update_password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_UpdatePassword;
