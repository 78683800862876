import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography, Button, Link, CircularProgress, ThemeProvider } from '@mui/material';
import { theme } from './ReactStyles';
import { useNavigate } from 'react-router-dom';
import './css/Unauthenticated.css';
import logopng from './pics/logo.png';
import { t } from './Util_format';

function Identification() {
  const navigate = useNavigate();
  const handleLogin = async () => {
    navigate("/kyc");
  }

  return (
    <ThemeProvider theme={theme}>
      <div id="page_login_otp">
        <div className="container">
          <div className="unauthenticated_left_container">
            <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
            <div className="overlayImage"></div>
          </div>

          <div className="unauthenticated_right_container">
            <div style={{ width: "100%" }}>
              <img src={logopng} alt={t("logo")} className="login_logo_mobile" />
            </div>

            {t("explain_kyc")}

            <Button
              variant="contained"
              color="primary"
              className="unauthenticated_button"
              sx={{
                borderRadius: "1rem",
                textTransform: "none",
              }}
              onClick={handleLogin}
            >
              {t("start_kyc")}
            </Button>

            <Link
              href="/login?action=clean-cookie"
              variant="body2"
              className="returnToLogin"
            >
              {t("logout")}
            </Link>

          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Identification;