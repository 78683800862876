import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal_ServicesListVendors from "./Modal_ServicesListVendors";
import Modal_ServicesListProducts from "./Modal_ServicesListProducts";
import Modal_ServicesBuy from "./Modal_ServicesBuy";
import { blue_title, textStyle_small_light } from "./ReactStyles";
import { t } from "./Util_format";
import { getCookieValue } from './App';
import { fetchPageData } from './Util_API_calls';
import Loading from './Loading';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FlightIcon from '@mui/icons-material/Flight';
import TvIcon from '@mui/icons-material/Tv';
import CodeIcon from '@mui/icons-material/Code';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import config from './config/env.json';

const productTypes = [
  { name: "mobile", Icon: SmartphoneIcon, color: "#4caf50" },
  { name: "entertainment", Icon: TvIcon, color: "#3f51b5" },
  { name: "shopping", Icon: ShoppingCartIcon, color: "#ff9800" },
  { name: "games", Icon: SportsEsportsIcon, color: "#f44336" },
  { name: "software", Icon: CodeIcon, color: "#9c27b0" },
  { name: "travel", Icon: FlightIcon, color: "#03a9f4" },
];

const paymentTypes = [
  { name: "bollettini896", Icon: ReceiptIcon, color: "#424242" },
  { name: "pagopa", Icon: PaymentsIcon, color: "#424242" },
  { name: "bolloauto", Icon: DirectionsCarIcon, color: "#424242" },
  { name: "mav", Icon: PaymentIcon, color: "#424242" },
  { name: "rav", Icon: MailOutlineIcon, color: "#424242" },
];

function Page_Services({ onDataChange, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [isLoading, setIsLoading] = useState(true);
  const [productType, setProductType] = useState("");
  const [isServicesListVendorsOpen, setisServicesListVendorsOpen] = useState(false);
  const [isServicesListProductsOpen, setisServicesListProductsOpen] = useState(false);
  const [isServicesBuyProductOpen, setisServicesBuyProductOpen] = useState(false);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 2) {
      const type = pathSegments[2];
      const vendor = pathSegments.length > 3 ? pathSegments[3] : "";
      const ean = pathSegments.length > 4 ? pathSegments[4] : "";

      const filterPaymentType = paymentTypes.find((e) => e.name === type);
      if (config.ENABLE_LOCAL_PAYMENT === "true" || !filterPaymentType) {
        setProductType(type); // Set the product type from the URL

        if (vendor && ean) {
          setisServicesBuyProductOpen(true); // Open buy product modal if ean is specified
        } else if (vendor) {
          setisServicesListProductsOpen(true); // Open products modal if a vendor is specified
        } else {
          setisServicesListVendorsOpen(true); // Otherwise, open vendors modal
        }
      }
    }
  }, [location]);


  const handleServicesListVendorsClose = () => {
    setisServicesListVendorsOpen(false);
    setProductType(""); // Reset modal type on close
    navigate('/services');
  };

  const handleServicesListProductsClose = () => {
    setisServicesListVendorsOpen(false);
    setisServicesListProductsOpen(false);
    setProductType(""); // Reset modal type on close
    navigate('/services');
  };

  const handleServicesBuyProductClose = () => {
    setisServicesListVendorsOpen(false);
    setisServicesListProductsOpen(false);
    setisServicesBuyProductOpen(false);
    setProductType(""); // Reset modal type on close
    navigate('/services');
  }

  const onNavigateProducts = useCallback(
    (type) => {
      setProductType(type); // Set the modal type before navigation
      setisServicesListVendorsOpen(true); // Open the modal for vendors
      navigate(`/services/${type}`); // Navigate to the specified service URL
    },
    [navigate]
  );

  const onNavigatePayments = useCallback(
    (type) => {
      setProductType(type); // Set the modal type before navigation
      setisServicesListVendorsOpen(true); // Open the modal for vendors
      navigate(`/services/${type}/payments/prepare`); // Navigate to the specified service URL
    },
    [navigate]
  );

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('epay');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }
      const data = apidata.data;
      if (onDataChange) {
        onDataChange(data);
      }
      setPageData(data); // Set the fetched data to pageData state
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const openVendorsModal = () => {
    setisServicesListVendorsOpen(true);
  };

  const OpenProductsModal = () => {
    setisServicesListProductsOpen(true);
  }

  useEffect(() => {
    get_page_data();
  }, []);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <>
      {isServicesListVendorsOpen && !isServicesListProductsOpen && !isServicesBuyProductOpen && (
        <Modal_ServicesListVendors
          isOpen={isServicesListVendorsOpen}
          onClose={handleServicesListVendorsClose}
          productType={productType}
          catalog={pageData?.catalog}
        />
      )}
      {isServicesListProductsOpen && !isServicesBuyProductOpen && (
        <Modal_ServicesListProducts
          isOpen={isServicesListProductsOpen}
          onClose={handleServicesListProductsClose}
          onOpenVendorsModal={openVendorsModal}
          productType={productType}
          catalog={pageData?.catalog}
        />
      )}
      {isServicesBuyProductOpen && (
        <Modal_ServicesBuy
          isOpen={isServicesBuyProductOpen}
          onClose={handleServicesBuyProductClose}
          productType={productType}
          catalog={pageData?.catalog}
          bankaccounts={pageData?.user_accounts.current_accounts}
          onOpenProductsModal={OpenProductsModal}
          userDetails={pageData?.details}
        />
      )}
      <Grid item xs={12} style={{ minWidth: isMobile ? "300px" : "500px", marginTop: "24px" }}>
        <Card style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
          <CardContent>
            <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
              {t("products")}
            </Typography>
            <Typography variant="body1" style={{ ...textStyle_small_light, }}>
              {t("services_note")}
            </Typography>
            <Grid container spacing={2} style={{ padding: "24px" }} justifyContent="center">
              {productTypes.map(({ name, Icon, color }) => (
                <Grid key={name} item xs={6} sm={4} md={2} style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{ height: "100%", width: "100%", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: color }}
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      onNavigateProducts(name);
                    }}
                  >
                    <Icon style={{ fontSize: "48px", color: "#fff" }} />
                    <Typography variant="body2" style={{ color: "#fff" }}>{t('category_' + name)}</Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        {/*    
        */}
        {config.ENABLE_LOCAL_PAYMENT === "true" && <Card style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", marginTop: '20px' }}>
          <CardContent>
            <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
              {t("payments")}
            </Typography>
            <Typography variant="body1" style={{ ...textStyle_small_light, }}>
              {t("payments_note")}
            </Typography>
            <Grid container spacing={2} style={{ padding: "24px" }} justifyContent="center">
              {paymentTypes.map(({ name, Icon, color }) => (
                <Grid key={name} item xs={6} sm={4} md={2} style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{ height: "100%", width: "100%", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: color }}
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      onNavigatePayments(name);
                    }}
                  >
                    <Icon style={{ fontSize: "48px", color: "#fff" }} />
                    <Typography variant="body2" style={{ color: "#fff" }}>{t('category_' + name)}</Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>}

      </Grid>
    </>
  );
}

export default Page_Services;
